import React from 'react';
import { Avatar, Dropdown, Popover, Whisper, Tooltip } from 'rsuite';
import MyProfileOption from './MenuOptions/MyProfileOption';
import AccountSettingsOption from './MenuOptions/AccountSettingsOption';

import './style.scss';
import HireToolOption from './MenuOptions/HireToolOption';
import PlanUpgradeOption from './MenuOptions/PlanUpgradeOption';
import InviteFriendOption from './MenuOptions/InviteFriendOption';
import BetaTesterOption from './MenuOptions/BetaTesterOption';
import LogoutOption from './MenuOptions/LogoutOption';
import ProfileProgress from './MenuOptions/ProfileProgress';
import { ArrowDown2 } from 'iconsax-react';


const renderMenu = ({ onClose, className }, ref) => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  const handleSelect = (eventKey) => {
    onClose();
  };

  return (
    <div className="rs-drawer-backdrop rs-anim-fade rs-anim-in">
      <Popover
        id="menu-user-personal"
        ref={ref}
        className={className}
        full
        visible={true}
        arrow={false}
      >
        <div className="menu-header">
          <p>{profile?.email}</p>
        </div>
        <hr />
        <Dropdown.Menu onSelect={handleSelect}>
          <MyProfileOption eventKey={1} />
          <AccountSettingsOption eventKey={2} isAdmin={profile.is_admin} />
          <ProfileProgress eventKey={3} profile={profile} />
          <HireToolOption eventKey={4} />
          <PlanUpgradeOption eventKey={5} />
          <InviteFriendOption eventKey={6} />
          <hr />
          <BetaTesterOption eventKey={7} />
          <LogoutOption eventKey={8} />
        </Dropdown.Menu>
      </Popover>
    </div>
  );
};

const UserMenu = ({ id, src }) => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  const getFirstName = (data) => {
    if (data !== undefined) {
      if (data.split(' ').length >= 2) {
        return `${data.split(' ')[0]} ${data.split(' ')[1].substring(0, 1)}`;
      } else {
        return data;
      }
    } else {
      return 'Turivius';
    }
  };

  return (
    <div className="menu-user">
      <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu}>
        <div className="menu-user_user-image">
          {src ? (
            <Avatar
              circle
              id={id}
              alt="foto de perfil"
              src={`data:image/*;base64, ${src}`}
            />
          ) : (
            <i className="ri-user-3-line" style={{ fontSize: '1.3333em' }} />
          )}
          <Whisper
            placement="bottom"
            trigger="hover"
            speaker={<Tooltip>Seu perfil</Tooltip>}
          >
            <label>{getFirstName(profile?.name)}</label>{' '}
          </Whisper>
          <ArrowDown2 size="14" color=" #667085"/>
        </div>
      </Whisper>
    </div>
  );
};

export default UserMenu;
