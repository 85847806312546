import create from 'zustand';
import { requester } from '../../requesters';

type COLLECTION_NAMES =
  | 'user_boards'
  | 'shared_w_boards'
  | 'public_to_company_boards';

type Board = {
  id: number;
};

type Collection = {
  [key in COLLECTION_NAMES]: {
    boards: Board[];
  };
};

interface CollectionState {
  loading: boolean;
  collections: Collection;
  addCollections: (collections: Collection) => void;
  fetch: () => void;
}

export const useCollections = create<CollectionState>((set: any) => ({
  loading: false,
  collections: {
    user_boards: {
      boards: [],
    },
    shared_w_boards: {
      boards: [],
    },
    public_to_company_boards: {
      boards: [],
    },
  },
  addCollections: (collections: any) =>
    set((state: any) => ({
      collections: {
        ...state.collections,
        ...collections,
      },
    })),
  fetch: async () => {
    let page = 1;
    let totalCount = 0;
    let totalPages = 0;
    const itemsPerPage = 12;
    let allCollections: any = [];
    const userId = localStorage.getItem('user');

    set((state: any) => ({
      ...state,
      loading: true,
    }));

    const firstResponse = await requester.collections.fetchCollections(
      userId,
      page,
    );
    allCollections = firstResponse;
    const counts = Object.values(firstResponse).map((obj: any) => obj.count);

    totalCount = Math.max(...counts);
    totalPages = Math.ceil(totalCount / itemsPerPage);

    for (page = 2; page <= totalPages; page++) {
      const response = await requester.collections.fetchCollections(
        userId,
        page,
      );
      allCollections = {
        user_boards: {
          boards: [
            ...allCollections.user_boards.boards,
            ...response.user_boards.boards,
          ],
        },
        shared_w_boards: {
          boards: [
            ...allCollections.shared_w_boards.boards,
            ...response.shared_w_boards.boards,
          ],
        },
        public_to_company_boards: {
          boards: [
            ...allCollections.public_to_company_boards.boards,
            ...response.public_to_company_boards.boards,
          ],
        },
      };
    }

    set(() => ({ collections: allCollections, loading: false }));
  },
}));
