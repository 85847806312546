import { CloseCircle, SearchNormal1, Warning2 } from 'iconsax-react';
import { useState, useEffect } from 'react';
import { Col, Grid, Input, InputGroup, Row } from 'rsuite';
import TuriviusPreloader from '../../../../../app/TuriviusPreloader';
import AddCardsToTagButton from './AddCardsToTagButton';
import DeleteTagButton from './DeleteTagButton';
import ReassignTagToCardsButton from './ReassignTagToCardsButton';
import RemoveCardsFromTagButton from './RemoveCardsFromTagButton';
import './style.scss';

const SearchTag = ({ cards, collectionId, collectionTags }) => {
  const cardIds = cards.map((card) => card.id);
  const [tags, setTags] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTagsToCardsMap, setActiveTagsToCardsMap] = useState([]);
  const [searchTag, setSearchTag] = useState('');

  function getTags() {
    if (collectionTags == null) {
      setTags([]);
      return;
    }

    const tags = [];
    Object.keys(collectionTags).map((tagIdx) => {
      let currTag = {
        id: collectionTags[tagIdx].id,
        title: collectionTags[tagIdx].title,
        active: true,
      };

      cardIds.forEach((cardId) => {
        if (!collectionTags[tagIdx].cards.includes(cardId))
          currTag.active = false;
      });

      tags.push(currTag);
    });

    setTags(tags);
    setFilterTags(tags);
  }

  function getActiveTagsToCardsMap() {
    if (collectionTags == null) {
      setActiveTagsToCardsMap([]);
      return;
    }

    let tmpActiveTagsToCardsMap = [];
    Object.keys(collectionTags).map((tagIdx) => {
      let currTag = { ...collectionTags[tagIdx] };

      cardIds.forEach((cardId) => {
        if (!currTag.cards.includes(cardId)) return;
        if (
          tmpActiveTagsToCardsMap.length &&
          tmpActiveTagsToCardsMap[tmpActiveTagsToCardsMap.length - 1].tagId ===
            currTag.id
        ) {
          tmpActiveTagsToCardsMap[
            tmpActiveTagsToCardsMap.length - 1
          ].cardIds.push(cardId);
        } else {
          tmpActiveTagsToCardsMap.push({
            cardIds: [cardId],
            tagId: currTag.id,
          });
        }
      });
    });

    setActiveTagsToCardsMap(tmpActiveTagsToCardsMap);
  }

  function searchingTag(value) {
    setFilterTags(
      tags.filter((tag) => tag.title.toLowerCase().includes(value)),
    );
  }

  useEffect(() => {
    setLoading(true);
    getTags();
    getActiveTagsToCardsMap();
    setLoading(false);
  }, [collectionTags]);

  return (
    <div className="search-tag-container">
      <InputGroup inside>
        <InputGroup.Addon>
          <SearchNormal1 size="15" color="#98A2B3" />
        </InputGroup.Addon>
        <Input
          placeholder="Buscar marcador"
          className="search-tag-container_search-input"
          value={searchTag}
          onChange={(value) => {
            searchingTag(value);
            setSearchTag(value);
          }}
        />
      </InputGroup>
      <div
        className={`search-tag-container_tags-list ${
          tags.length > 0 ? null : 'empty'
        }`}
      >
        {loading ? (
          <Grid fluid>
            <Col xs={24} sm={24} style={{ height: 50 }}>
              <TuriviusPreloader />
            </Col>
          </Grid>
        ) : tags.length > 0 ? (
          filterTags?.map((tag) => (
            <Grid
              fluid
              className="search-tag-container_tags-list_tag-container mr-2"
              key={tag.id}
            >
              <Row gutter={5}>
                <Col sm={14}>
                  <p>{tag.title}</p>
                </Col>
                <Col sm={3}>
                  <DeleteTagButton collectionId={collectionId} tag={tag} />
                </Col>
                <Col sm={7}>
                  {tag.active ? (
                    <RemoveCardsFromTagButton
                      cards={cards}
                      collectionId={collectionId}
                      activeTagsToCardsMap={activeTagsToCardsMap}
                      fullWidth={true}
                      appearance="danger"
                      icon={<CloseCircle size={16} color="white" />}
                    >
                      Remover
                    </RemoveCardsFromTagButton>
                  ) : activeTagsToCardsMap.length ? (
                    <ReassignTagToCardsButton
                      cards={cards}
                      collectionId={collectionId}
                      tag={tag}
                      activeTagsToCardsMap={activeTagsToCardsMap}
                    />
                  ) : (
                    <AddCardsToTagButton
                      cards={cards}
                      collectionId={collectionId}
                      tag={tag}
                    />
                  )}
                </Col>
              </Row>
            </Grid>
          ))
        ) : (
          <div className="search-tag-container_empty">
            <Warning2 size="32" color="#FDB022" variant="Outline" />
            <h6>Você não possui marcadores nessa coleção</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchTag;
