import { Login } from 'iconsax-react';
import HeaderFilter from './components/HeaderFilter/HeaderFilter';
import './NewFilterList.scss';
import { useState } from 'react';

export const NewFilterList = ({
  updateSelectedFilters,
  setValueStyles,
  search,
  filtersProps,
  setNewValue,
  setValitadionFilter,
  handleStateUpdate,
  setClearFiltro,
  trigger,
  setTrigger,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleModal = (type) => {
    setModalType(type);
    setIsOpenModal(true);
    setValueStyles(true);
    setValitadionFilter(true);
  };

  const CloseModal = () => {
    setIsOpenModal(false);
    setModalType('');
    setValueStyles(false);
  };

  const clearFilter = async () => {
    await setClearFiltro(true);

    filtersProps.saveFilters(
      filtersProps.history,
      filtersProps.match,
      encodeURIComponent(filtersProps.query),
    );
  };

  return (
    <div className="box-filter-header">
      <div className="filter-title">Filtros</div>
      <div className="filter-actions">
        <span onClick={() => handleModal('salvar')}>Salvar -</span>
        <span onClick={() => handleModal('meusFiltros')}>Meus filtros</span>
      </div>
      <div className="filter-icon">
        <Login size="24" color="#101828" />
      </div>
      {isOpenModal && (
        <HeaderFilter
          CloseModal={CloseModal}
          isCarregarFiltros={modalType === 'meusFiltros'}
          updateSelectedFilters={updateSelectedFilters}
          search={search}
          filtersProps={filtersProps}
          setNewValue={setNewValue}
          handleStateUpdate={handleStateUpdate}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      )}
    </div>
  );
};
