import { ClipboardClose, TickCircle } from 'iconsax-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Message, toaster } from 'rsuite';
import { Button } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import { Types as BulkactionTypes } from '../../../../../store/ducks/collectionsBulkaction';
import { useCollections } from '../../../../../store/ducks/theCollections';

const UndoRemoveCardFromCollectionMessage = ({
  cards,
  collectionId,
  closeToast,
}) => {
  const cardIds = cards.map((card) => card.id);
  const { fetch } = useCollections();

  const UndoRemoveCardFromCollectionHandler = () => {
    closeToast();

    requester.collections
      .addCardsToCollection(
        localStorage.getItem('user'),
        collectionId,
        cardIds,
        '',
      )
      .then(() => {
        fetch();
        toast.success(
          <span className="text-base leading-7 text-[#475467]">
            {cardIds.length === 1
              ? 'A decisão foi adicionada a coleção novamente'
              : 'As decisões foram adicionadas a coleção novamente'}
          </span>,
          {
            icon: <TickCircle size="sm" color="#07bc0c" />,
          },
        );
      })
      .catch((err) => {
        console.error(err);
        if (err.message.error === 'COLLECTION_SIZE_EXCEEDED')
          toast.error(
            'Você não pode adicionar mais que 300 decisões a sua coleção.',
            {
              icon: <img src={redCrossIcon} style={{ width: '16px' }} />,
            },
          );
        else
          toaster.push(
            <Message header="Ocorreu um erro" type="error" showIcon closable>
              Houve um problema de conexão, tente novamente.
            </Message>,
            { duration: 4000 },
          );
      });
  };

  return (
    <div className="flex items-center justify-between">
      <span className="text-base leading-7 text-[#475467]">
        {cards.length === 1 ? 'Decisão removida' : 'Decisões removidas'}
      </span>
      <span
        className="text-base leading-6 text-[#0069F5] cursor-pointer"
        onClick={UndoRemoveCardFromCollectionHandler}
      >
        Desfazer
      </span>
    </div>
  );
};

const RemoveCardFromCollectionButton = ({
  children,
  cards,
  context,
  collectionId,
  appearance,
  icon,
  loadingIcon,
  className,
}) => {
  const user = localStorage.getItem('user');
  const [loading, setLoading] = useState(false);
  const { fetch } = useCollections();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();

  const removeCardFromCollectionHandler = () => {
    setLoading(true);

    requester.collections
      .removeCardsFromCollection(
        user,
        collectionId,
        cards?.map((card) => card.id),
        context,
      )
      .then(() => {
        fetch();
        toast.error(
          <UndoRemoveCardFromCollectionMessage
            cards={cards}
            collectionId={collectionId}
          />,
          {
            icon: <ClipboardClose size="sm" color="#e74c3c" />,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            autoClose: false,
            className: 'w-[335px] right-[23px]',
          },
        );
        if (!!matchPath(pathname, { path: `/colecoes/${collectionId}` }))
          dispatch({ type: BulkactionTypes.UNSELECT_CARDS, cards: cards });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message header="Ocorreu um erro" type="error" showIcon closable>
            Houve um problema de conexão, tente novamente
          </Message>,
          { duration: 4000 },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        className={className}
        appearance={appearance}
        onClick={removeCardFromCollectionHandler}
        icon={loading ? loadingIcon : icon}
        size="sm"
        onlyIconInXSResolution
      >
        {children}
      </Button>
    </>
  );
};

export default RemoveCardFromCollectionButton;
