import React, { useState, useEffect, useRef } from 'react';
import { Button, Card } from '../../../../../ds-components';
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import { cards } from 'src/requesters/requesters/cards';
import { format, isValid, parse, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { Whisper, Tooltip } from 'rsuite';

import './tooltip.scss';

interface SearchHistoryItem {
  query: string;
  filters: Record<string, any>;
  user: number;
  dt: string;
}

interface Filter {
  field_name: string;
  default: any;
}

const RecentSearchBox: React.FC = () => {
  const history = useHistory();
  const [defaultFilters, setDefaultFilters] = useState<Record<string, any>>({});
  const [pagination, setPagination] = useState(1);
  const [paginationHistory, setPaginationHistory] = useState(1);
  const [topics, setTopics] = useState<SearchHistoryItem[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const itemsPerPage = 5;
  const itemsPerPageHistory = 10;
  const middleItem = Math.ceil(topics.length / 2);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const historyData = await cards.searchHistory();
      setTopics(historyData);
    } catch (error) {
      console.error(
        'Não foi possível acessar o histórico de pesquisas:',
        error,
      );
      setError(
        'Não foi possível carregar o histórico de pesquisas. Por favor, tente novamente mais tarde.',
      );
    }
  };

  const handleSearchClick = (search: {
    query: string;
    filters: Record<string, any>;
  }) => {
    history.push(
      `/pesquisa/resultados?query=${encodeURIComponent(search.query)}${search.filters ? `&filters=${encodeURIComponent(JSON.stringify(search.filters))}` : ''}`,
    );
  };

  const fetchAndNavigateToSearchResults = async (query: string) => {
    try {
      const filtersResponse: Filter[] = await cards.getFilters();
      const filters = filtersResponse;
      let filtersObject: Record<string, any> = {};
      let entityFilter: Filter | {} = {};

      filters.forEach((filter: Filter) => {
        if (filter.field_name === 'entity') {
          filtersObject['entities'] = filter.default;
          entityFilter = { ...filter };
        } else {
          filtersObject[filter.field_name] = filter.default;
        }
      });
      setDefaultFilters(filtersObject);
      handleSearchClick({ query, filters: filtersObject });
    } catch (error) {
      console.error('Não foi possível recuperar os filtros:', error);
    }
  };

  const handlePreviousPageRecentSearches = () => {
    setPagination((prevPage) => {
      return Math.max(prevPage - 1, 1);
    });
  };

  const handleNextPageRecentSearches = () => {
    setPagination((prevPage) => {
      return Math.min(prevPage + 1, Math.ceil(topics.length / itemsPerPage));
    });
  };

  const handlePreviousPageHistory = () => {
    setPaginationHistory((prevPage) => {
      return Math.max(prevPage - 1, 1);
    });
  };

  const handleNextPageHistory = () => {
    setPaginationHistory((prevPage) => {
      return Math.min(
        prevPage + 1,
        Math.ceil(topics.length / itemsPerPageHistory),
      );
    });
  };

  const handleShowHistory = () => {
    setShowHistory(true);
    setPaginationHistory(1);
  };

  const handleCloseHistory = () => setShowHistory(false);

  const startIndex = (pagination - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = topics.slice(startIndex, endIndex);

  const startIndexHistory = (paginationHistory - 1) * itemsPerPageHistory;
  const endIndexHistory = startIndexHistory + itemsPerPageHistory;
  const currentItemsHistory = topics.slice(startIndexHistory, endIndexHistory);

  const formatDate = (dateString: string): string => {
    const date = parse(dateString, 'dd/MM/yyyy - HH:mm:ss', new Date());
    if (!isValid(date)) {
      console.warn('Formato de data inválido:', dateString);
      return 'Data inválida';
    }
    return format(date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
  };

  return (
    <div style={{ width: '47%' }}>
      <Card title="">
        <div className="recent-box">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold ml-4">Pesquisas recentes</h2>
            <Whisper
              placement="top"
              trigger="hover"
              speaker={<Tooltip>Veja seu histórico de buscas</Tooltip>}
            >
              <button
                onClick={handleShowHistory}
                className="text-blue-500 text-xs"
              >
                Ver todas
              </button>
            </Whisper>
          </div>
          {currentItems.length > 0 ? (
            currentItems.map((topic, index) => (
              <React.Fragment key={index}>
                <div className="recent-box_content" key={index}>
                  <div className="flex justify-between items-center">
                    <p className="font-semibold ml-2 custom-truncate w-64">
                      {topic.query}
                    </p>
                    <div>
                      <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={<Tooltip>Ir para o resultado</Tooltip>}
                      >
                        <img
                          src="/back-square.svg"
                          alt="Icone voltar"
                          className="icon-back-square recent_box_icon-back-square cursor-pointer"
                          onClick={async (e) => {
                            e.stopPropagation();
                            await fetchAndNavigateToSearchResults(topic.query);
                          }}
                        />
                      </Whisper>
                    </div>
                  </div>
                  <div className="recent-box_images">
                    <img
                      src="/calendar.svg"
                      alt="Icone calendário"
                      className="icon-calendar ml-2"
                    />
                    <p className="text-sm custom-text-color ml-1">
                      Pesquisando em {formatDate(topic.dt)}
                    </p>
                  </div>
                </div>
                {index < currentItems.length - 1 && (
                  <hr className=" border-t border-gray-300" />
                )}
              </React.Fragment>
            ))
          ) : (
            <p className="ml-4 mt-4">Nenhuma pesquisa recente encontrada.</p>
          )}
          <hr className="my-2 border-t border-gray-300" />
          {/* paginação */}
          <div className="recent-box_footer flex items-center justify-center mt-2">
            <span className="pagination">
              Página {pagination} de {Math.ceil(topics.length / itemsPerPage)}
            </span>
            <div className="flex space-x-2">
              <Button
                className="recent-box_button"
                appearance="secondary"
                size="xs"
                icon={<ArrowLeft2 color="#667085" size={16} />}
                onClick={handlePreviousPageRecentSearches}
                disabled={pagination === 1}
              >
                Anterior
              </Button>
              <Button
                className="recent-box_button"
                appearance="secondary"
                iconPosition="right"
                size="xs"
                icon={<ArrowRight2 color="#667085" size={16} />}
                onClick={handleNextPageRecentSearches}
                disabled={
                  pagination === Math.ceil(topics.length / itemsPerPage)
                }
              >
                Próxima
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {showHistory && (
        <div className="absolute right-0 top-0 w-[401px] h-full bg-white shadow-lg z-10">
          <Card title="">
            <div className="flex flex-col h-full">
              <div className="relative flex-shrink-0">
                <h2 className="text-lg font-semibold ml-4">
                  Histórico de pesquisas
                </h2>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={<Tooltip>Fechar</Tooltip>}
                >
                  <button
                    onClick={handleCloseHistory}
                    className="absolute top-2 right-2 text-xl"
                  >
                    &times;
                  </button>
                </Whisper>
              </div>
              <div className="flex-grow mt-2">
                <h3 className="text-md font-normal text-gray-500 ml-4 mb-6">
                  Veja abaixo as suas pesquisas mais recentes
                </h3>
                {currentItemsHistory.length > 0 ? (
                  currentItemsHistory.map((topic, index) => (
                    <div className="recent-box_content" key={index}>
                      <div className="flex justify-between items-center">
                        <p className="font-semibold ml-2 truncate w-64">
                          {topic.query}
                        </p>
                        <div className="tooltip-container">
                          <img
                            src="/back-square.svg"
                            alt="Icone voltar"
                            className="icon-back-square recent_box_icon-back-square cursor-pointer"
                            onClick={async (e) => {
                              e.stopPropagation();
                              await fetchAndNavigateToSearchResults(
                                topic.query,
                              );
                            }}
                          />
                          <div className="tooltip-text">
                            Ir para o resultado
                          </div>
                        </div>
                      </div>
                      <div className="recent-box_images">
                        <img
                          src="/calendar.svg"
                          alt="Icone calendário"
                          className="icon-calendar ml-2"
                        />
                        <p className="text-sm custom-text-color ml-1">
                          Pesquisando em {formatDate(topic.dt)}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Nenhum histórico de pesquisa encontrado.</p>
                )}
                {/* paginação */}
                <div className="recent-box_button flex items-center justify-center mt-5">
                  <span className="pagination text-gray-600 text-xs">
                    Página {paginationHistory} de{' '}
                    {Math.ceil(topics.length / itemsPerPageHistory)}
                  </span>
                  <div className="flex space-x-2">
                    <Button
                      className="recent-box_button w-75 h-5 p-1 border-gray-400 rounded hover:bg-gray-200 text-[12px] ml-2"
                      appearance="secondary"
                      size="xs"
                      icon={<ArrowLeft2 color="#667085" size={16} />}
                      onClick={handlePreviousPageHistory}
                      disabled={paginationHistory === 1}
                    >
                      Anterior
                    </Button>
                    <Button
                      className="recent-box_button w-75 h-5 p-1 border-gray-400 rounded hover:bg-gray-200 text-[12px] ml-2"
                      appearance="secondary"
                      iconPosition="right"
                      size="xs"
                      icon={<ArrowRight2 color="#667085" size={16} />}
                      onClick={handleNextPageHistory}
                      disabled={
                        paginationHistory ===
                        Math.ceil(topics.length / itemsPerPageHistory)
                      }
                    >
                      Próxima
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default RecentSearchBox;
