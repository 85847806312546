import { AddCircle, TickCircle } from 'iconsax-react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Input, InputGroup, Message, toaster } from 'rsuite';
import redCrossIcon from '../../../../../assets/svgs/red-cross.svg';
import { Button, SpinningLoader } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import { useCollections } from '../../../../../store/ducks/theCollections';
import SeeCollectionMessage from '../BulkActionBar/SeeCollectionMessage';

const CreateAndAddToCollection = ({
  cards,
  context,
  query,
  handleChangeNavigation,
}) => {
  const [collectionName, setCollectionName] = useState(query);
  const [fetching, setFetching] = useState(false);
  const [created, setCreated] = useState(false);

  const { fetch: fetchCollections } = useCollections();

  const handleChangeCollectionName = (s, e) => {
    setCollectionName(e.target.value);
  };

  const createCollectionAndAddCard = async () => {
    setFetching(true);
    requester.collections
      .createCollection(
        localStorage.getItem('user'),
        collectionName,
        '',
        context,
      )
      .then((response) => {
        toast.success(
          <div className="flex items-center justify-between">
            <span className="text-base leading-7 text-[#475467]">
              Coleção criada com sucesso
            </span>
          </div>,

          {
            icon: <TickCircle size="sm" color="#07bc0c" />,
            className: 'w-[390px] right-[77px] cursor-pointer',
          },
        );
        collectionId = response.id;
        return requester.collections.addCardsToCollection(
          localStorage.getItem('user'),
          collectionId,
          cards.map((card) => card.id),
          context,
        );
      })
      .then(() => {
        fetchCollections();
      })
      .then(() => {
        toast.success(
          <SeeCollectionMessage
            collectionId={collectionId}
            notificationContent={
              cards.length === 1 ? 'Adicionado' : 'Adicionados' + ' a coleção'
            }
          />,
          {
            icon: <TickCircle size="sm" color="#12B76A" />,
            className: 'w-[390px] right-[77px] cursor-pointer',
            progressStyle: { background: '#12B76A' },
            closeButton: false,
          },
        );
        setCreated(true);
        handleChangeNavigation(1);
        const createTimeout = setTimeout(() => {
          setCreated(false);
        }, 3000);

        return () => {
          clearTimeout(createTimeout);
        };
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message header="Ocorreu um erro" type="error" showIcon closable>
            Houve um problema de conexão, tente novamente.
          </Message>,
          { duration: 4000 },
        );
      })
      .finally(() => {
        setFetching(false);
      });
  };

  return (
    <div className="create-collection p-3">
      <InputGroup inside>
        <InputGroup.Button>
          <AddCircle size={20} color="#98A2B3" />
        </InputGroup.Button>
        <Input
          id={`create-and-add-save-to-collection-${Math.random()}`}
          value={collectionName}
          label="Insira o nome da coleção"
          variant="outlined"
          onChange={handleChangeCollectionName}
          size={'md'}
          placeholder="Nova coleção aqui"
        />
      </InputGroup>

      {created ? (
        <Button
          size="sm"
          appearance="success"
          data-testid="create-collection-button-success"
          icon={<TickCircle size={14} color="#FFFFFF" />}
          block
        >
          Adicionado
        </Button>
      ) : (
        <Button
          appearance="primary"
          size="sm"
          onClick={() => createCollectionAndAddCard()}
          icon={
            fetching ? (
              <SpinningLoader color="white" size="sm" />
            ) : (
              <AddCircle size={16} color="#FFFFFF" />
            )
          }
        >
          Criar coleção
        </Button>
      )}
    </div>
  );
};

export default CreateAndAddToCollection;
