import { useEffect, useState } from 'react';
import RemoveCardsFromTagButton from './RemoveCardsFromTagButton';

const RemoveTagDecision = ({
  children,
  cards,
  collectionId,
  collectionTags,
  numTheCardsWithTag,
  appearance,
  icon,
  className,
}) => {
  const cardIds = cards.map((card) => card.id);
  const [activeTagsToCardsMap, setActiveTagsToCardsMap] = useState([]);

  useEffect(() => {
    getActiveTagsToCardsMap();
  }, [collectionTags, cards]);

  function getActiveTagsToCardsMap() {
    if (collectionTags == null) {
      setActiveTagsToCardsMap([]);
      return;
    }

    let tmpActiveTagsToCardsMap = [];

    Object.keys(collectionTags).map((tagIdx) => {
      let currTag = { ...collectionTags[tagIdx] };

      cardIds.forEach((cardId) => {
        if (!currTag.cards.includes(cardId)) return;
        if (
          tmpActiveTagsToCardsMap.length &&
          tmpActiveTagsToCardsMap[tmpActiveTagsToCardsMap.length - 1].tagId ===
            currTag.id
        ) {
          tmpActiveTagsToCardsMap[
            tmpActiveTagsToCardsMap.length - 1
          ].cardIds.push(cardId);
        } else {
          tmpActiveTagsToCardsMap.push({
            cardIds: [cardId],
            tagId: currTag.id,
          });
        }
      });
    });

    setActiveTagsToCardsMap(tmpActiveTagsToCardsMap);
  }

  return (
    <RemoveCardsFromTagButton
      cards={cards}
      collectionId={collectionId}
      activeTagsToCardsMap={activeTagsToCardsMap}
      numTheCardsWithTag={numTheCardsWithTag}
      fullWidth={false}
      appearance={appearance}
      icon={icon}
      className={className}
    >
      {children}
    </RemoveCardsFromTagButton>
  );
};

export default RemoveTagDecision;
