import React from 'react';
import SpinningLoader from '../Loaders/SpinningLoader';
import { Tooltip, Whisper } from 'rsuite';
import { useLocation } from 'react-router-dom';
import { cn } from '../../../lib/utils';

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  children?: string | React.ReactElement;
  appearance:
    | 'primary'
    | 'primary-dark'
    | 'secondary'
    | 'minimal'
    | 'white'
    | 'success'
    | 'danger';
  outline?: boolean;
  size: 'sm' | 'md' | 'lg' | 'xs';
  fullWidth?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  block?: boolean;
  loading?: boolean;
  onClick?: () => void;
  ref?: string;
  className?: string;
  tooltipText?: string;
  onlyIconInXSResolution?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  children,
  icon,
  iconPosition = 'left',
  size = 'md',
  appearance = 'primary',
  outline,
  disabled,
  block,
  loading,
  onClick,
  ref,
  className,
  fullWidth,
  tooltipText,
  onlyIconInXSResolution,
  ...props
}) => {
  const location = useLocation();

  return (
    <>
      {loading ? (
        <button
          className={`tv-btn tv-btn-${size}${
            fullWidth ? '_full' : ''
          } tv-btn-${appearance}${outline ? '_outline' : ''} font-medium ${
            disabled ? 'tv-btn-disabled' : ''
          } ${block ? 'tv-btn-block' : ''} ${className ? className : ''}`}
          disabled={disabled}
          {...props}
        >
          <SpinningLoader
            color={
              (appearance === 'primary' ||
                'primary-dark' ||
                'success' ||
                'error') &&
              !outline
                ? 'white'
                : appearance
            }
            size="sm"
          />
        </button>
      ) : (
        <>
          {tooltipText ? (
            <>
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>{tooltipText}</Tooltip>}
              >
                <button
                  onClick={onClick}
                  ref={ref}
                  type={type}
                  disabled={disabled}
                  className={`tv-btn tv-btn-${size}${
                    fullWidth ? '_full' : ''
                  } tv-btn-${appearance}${outline ? '_outline' : ''} font-medium ${
                    disabled ? 'tv-btn-disabled' : ''
                  } ${block ? 'tv-btn-block' : ''} ${className ? className : ''}`}
                  {...props}
                >
                  {iconPosition === 'left' && icon}
                  {children && (
                    <span
                      className={cn(
                        location.pathname.includes('colecoes')
                          ? 'hidden 2xl:block'
                          : 'hidden xl:block',
                      )}
                    >
                      {children}
                    </span>
                  )}
                  {iconPosition === 'right' && icon}
                </button>
              </Whisper>
            </>
          ) : (
            <button
              onClick={onClick}
              ref={ref}
              type={type}
              disabled={disabled}
              className={`tv-btn tv-btn-${size}${
                fullWidth ? '_full' : ''
              } tv-btn-${appearance}${outline ? '_outline' : ''} font-medium ${
                disabled ? 'tv-btn-disabled' : ''
              } ${block ? 'tv-btn-block' : ''} ${className ? className : ''}`}
              {...props}
            >
              {iconPosition === 'left' && icon}
              {children && (
                <span
                  className={
                    onlyIconInXSResolution
                      ? cn(
                          location.pathname.includes('colecoes/')
                            ? 'hidden 2xl:block'
                            : 'hidden xl:block',
                        )
                      : ''
                  }
                >
                  {children}
                </span>
              )}
              {iconPosition === 'right' && icon}
            </button>
          )}
        </>
      )}
    </>
  );
};
export default Button;
