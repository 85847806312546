import { Nav } from 'rsuite';
import './style.scss';

const CustomNav = ({ active, onSelect, items, ...props }) => {
  return (
    <Nav className="navbar" {...props} activeKey={active} onSelect={onSelect}>
      {items?.map((item, index) => (
        <Nav.Item key={index} eventKey={item.eventKey} active={item.active}>
          {item.title}
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default CustomNav;
