import { Book1, CloseCircle } from 'iconsax-react';
import { Drawer } from 'rsuite';
import MainActivities from './MainActivities';
import HelperCenter from './HelperCenter';
import AdditionalResources from './AdditionalResources';
import { useKnowledgeStore } from '../../store/ducks/knowledge';
import Notifications from '../../modules/account/notifications/components/Notifications/Notifications';
import { Whisper, Tooltip } from 'rsuite';


const KnowledgeHubButton = ({ session, openSuccessChecklist }) => {
  const { isOpen, open, close } = useKnowledgeStore();

  const handleDrawer = () => {
    isOpen ? close() : open();
  };

  function getRoute() {
    switch (session) {
      case 'collections':
      case 'collection':
        return 'Coleções';
      case 'monitoring':
        return 'Monitoramento';
      case 'jurimetrics-trabalhist':
        return 'Jurimetria Trabalhista';
      case 'dashboard-empresarial':
        return 'Área Empresarial';

      default:
        return 'Não identificada';
    }
  }

  return (
    <>
     <Whisper
       placement="bottom"
       trigger="hover"
       speaker={<Tooltip>Dúvidas frequentes</Tooltip>}
       >
      <button className="knowledge-hub-button" onClick={handleDrawer}>
        <Book1 size="18" color="#0069F5" />
      </button>
      </Whisper>

      <Notifications searchPage />

      <Drawer
        className="tv-drawer-primary"
        size="sm"
        open={isOpen}
        onClose={handleDrawer}
      >
        <Drawer.Header closeButton={false} className="tv-drawer-primary_header">
          <div style={{ textAlign: 'end', width: '100%' }}>
          <Whisper
       placement="bottom"
       trigger="hover"
       speaker={<Tooltip>Fechar</Tooltip>}
       >
            <CloseCircle
              size="24"
              color="#FFFFFF"
              onClick={handleDrawer}
              style={{ cursor: 'pointer' }}
            />
             </Whisper>
          </div>
          {session != 'jurisprudence' ? (
            <h6 className="tv-drawer-primary_header_title with-route">
              <p>Central de Conhecimento /</p> {getRoute()}
            </h6>
          ) : (
            <h6 className="tv-drawer-primary_header_title">
              Central de Conhecimento
            </h6>
          )}
          <label className="tv-drawer-primary_header_subtitle">
            Principais atividades para obter sucesso na ferramenta
          </label>
        </Drawer.Header>
        <Drawer.Body className="tv-drawer-primary_body">
          <MainActivities
            handleDrawer={handleDrawer}
            openSuccessChecklist={openSuccessChecklist}
          />
          <HelperCenter
            session={session === 'collection' ? 'collections' : session}
          />
          <AdditionalResources />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default KnowledgeHubButton;
