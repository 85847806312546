const AccessToCollectionDenied = () => {
  return (
    <div className="flex flex-col items-center mt-8 w-full">
      <img
        style={{ width: 300 }}
        className="mb-4"
        alt="Acesso a coleção negado"
        src="/access-denied-lock.svg"
      />
      <p className="font-bold">Você não possui acesso a essa Coleção</p>
    </div>
  );
};

export default AccessToCollectionDenied;
