import {
  ArrowDown2,
  ArrowRight2,
  ArrowUp2,
  Calendar,
  ClipboardClose,
  Tag,
  UserEdit,
} from 'iconsax-react';
import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip, Whisper } from 'rsuite';
import { Button } from '../../../../../../ds-components';
import { Types as BulkactionTypes } from '../../../../../../store/ducks/collectionsBulkaction';
import AddTagDecision from '../../../../../collections/components/Collection/AddTagDecision';
import DecisionsTimeline from '../../Buttons/DecisionsTimeline';
import ReadMoreOrLessButton from '../../Buttons/ReadMoreOrLessButton';
import ResultButtonGroup from '../../Buttons/ResultButtonGroup';
import FavorabilityModal from '../../Modals/FavorabilityModal';
import MoreInfoResultCard from './MoreInfoResultCard';
import { ResultCardInteiroTeor } from './ResultCardInteiroTeor';
import { Badge } from './badge';
import { cn } from '../../../../../../lib/utils';
import { Checkbox } from '../../../../../../components/ui/checkbox';

import DOMPurify from 'dompurify';

export const ResultCard = ({
  card,
  collectionId,
  query,
  theMatchResultsThatHaveCollections,
  collectionTags,
}) => {
  const { search, pathname } = useLocation();
  const [showAllText, setShowAllText] = useState(false);
  const [openTimeline, setOpenTimeline] = useState(false);
  const [isBeingHovered, setIsBeingHovered] = useState(false);

  const [maxHeight, setMaxHeight] = useState(55); // Inicialmente 55%
  const contentRef = useRef(null);

  const hasFullText = useMemo(() => search.search('full_text') > -1, [search]);
  const isInMonitoring = useMemo(
    () => pathname.includes('/monitoramento'),
    [pathname],
  );

  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const isSelected = useMemo(
    () => selectedCards.some((selectedCard) => selectedCard.id === card.id),
    [selectedCards],
  );

  const dispatch = useDispatch();

  const bulkactionCheckboxHandler = () => {
    if (selectedCards.some((selectedCard) => selectedCard.id === card.id))
      dispatch({ type: BulkactionTypes.UNSELECT_CARDS, cards: [card] });
    else if (selectedCards.length >= 30)
      toast.warning(
        <span className="text-base leading-7 text-[#475467]">
          Você pode selecionar até 30 decisões no máximo
        </span>,
        {
          icon: <ClipboardClose size="sm" color="#F79009" />,
        },
      );
    else dispatch({ type: BulkactionTypes.SELECT_CARDS, cards: [card] });
  };

  const handleOnMouseEnter = () => {
    setIsBeingHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsBeingHovered(false);
  };

  // Função para sanitizar o conteúdo HTML
  const sanitizeContent = (content) => {
    return DOMPurify.sanitize(content);
  };

  //Expande de forma progressiva
  /*  const handleExpand = () => {
    const totalHeight = contentRef.current?.scrollHeight || 0;
    const expandBy = 150;
     if (maxHeight < totalHeight) {
      setMaxHeight((prev) => Math.min(prev + expandBy, totalHeight));
    }
     if (maxHeight + expandBy >= totalHeight) {
      setShowAllText(true); 
    }
  };
  */

  const handleExpand = () => {
    if (!showAllText) {
      const totalHeight = contentRef.current?.scrollHeight || 0;
      const newHeight = Math.min(maxHeight + 12, 100);
      setMaxHeight(newHeight);
    }
    setShowAllText(true);
  };

  const handleReduce = () => {
    if (showAllText) {
      setShowAllText(false);
      setMaxHeight(55);

      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }
  };

  const handleToggle = () => {
    if (showAllText) {
      handleReduce();
    } else {
      handleExpand();
    }
  };

  const handleScroll = useCallback(() => {
    const content = contentRef.current;
    if (content) {
      const isAtBottom =
        content.scrollTop + content.clientHeight >= content.scrollHeight - 1;
      if (isAtBottom && !showAllText) {
        setShowAllText(true);
      }
    }
  }, [showAllText]);

  useEffect(() => {
    const currentRef = contentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const totalHeight = contentRef.current?.scrollHeight || 0;
    if (maxHeight >= 100 && !showAllText) {
      setShowAllText(true);
    }
  }, [maxHeight, showAllText]);

  const renderSection = (title, content) => {
    if (title === 'Inteiro Teor') {
      return null; // Não renderiza "Inteiro Teor" dentro das outras seções
    }
    return (
      <>
        <h6 className="font-semibold">{title}</h6>
        <p
          className="text-justify text-sm font-light"
          dangerouslySetInnerHTML={{ __html: sanitizeContent(content) }}
        />
      </>
    );
  };

  const sectionsWithoutInteiroTeor = useMemo(() => {
    return (card.content || []).filter(
      (section) => section.title !== 'Inteiro Teor',
    );
  }, [card.content]);

  return (
    <div
      className={'flex flex-row'}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {!isInMonitoring && (
        <div>
          <div className="flex flex-row items-center">
            <Whisper
              trigger="hover"
              speaker={
                <Tooltip>{isSelected ? 'Descartar' : 'Selecionar'}</Tooltip>
              }
              placement="top"
            >
              <div className="flex">
                <Checkbox
                  id={card.id}
                  className="border-2 border-[#D0D5DD]"
                  checked={selectedCards.some(
                    (selectedCard) => selectedCard.id === card.id,
                  )}
                  onCheckedChange={bulkactionCheckboxHandler}
                />
              </div>
            </Whisper>
            <ArrowRight2
              size="16"
              color="#667085"
              className={`mr-3 ${isBeingHovered ? '' : 'invisible'}`}
            />
          </div>
        </div>
      )}
      <div
        className={cn(
          isInMonitoring
            ? 'tv-resultcard monitoring'
            : `tv-resultcard border-3 border-solid ${isSelected ? 'border-blue-500' : isBeingHovered ? 'border-[#84CAFF]' : 'border-transparent'}`,
        )}
      >
        <div id="tv-resultcard" className="gap-6 flex items-start">
          <Badge className="my-auto">{card.entity.name}</Badge>
          <div
            className="gap-2 flex flex-col"
            style={{ position: 'relative', zIndex: 0 }}
          >
            <h6 className="font-bold">{card.name}</h6>
            <div className="gap-6 flex">
              <div className="gap-2 flex items-center">
                {card.relator.name ? (
                  <>
                    <UserEdit size="16" color="#84CAFF" />
                    <span className="text-sm leading-6 font-medium text-[#667085]">
                      Relator: {card.relator.name}
                    </span>
                  </>
                ) : null}
              </div>
              <div className="gap-2 flex items-center">
                <Calendar size="16" color="#84CAFF" />
                <span className="text-xs font-normal leading-5 text-[#98A2B3]">
                  {' '}
                  {card.dataPub}
                </span>
              </div>
              <span className="text-xs font-normal leading-5 text-[#98A2B3]">
                {card.dataJul}
              </span>
            </div>
            <div>
              {card.chips !== null ? (
                card.chips?.map((chip, index) => {
                  return (
                    <div
                      key={chip + index}
                      className="sub-buttons"
                      style={{ position: 'relative' }}
                    >
                      <FavorabilityModal key={index} chip={chip} card={card} />
                      {collectionId ? null : (
                        <MoreInfoResultCard
                          card={card}
                          collectionId={collectionId}
                          context={null}
                          segment={'tributario'}
                        />
                      )}
                      {collectionId ? (
                        <AddTagDecision
                          cards={[card]}
                          collectionId={collectionId}
                          collectionTags={collectionTags}
                          appearance="secondary"
                          icon={<Tag size="16" color="#84CAFF" />}
                          className="add-tag-container"
                        />
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <div className="sub-buttons" style={{ position: 'relative' }}>
                  <FavorabilityModal card={card} />
                  {collectionId ? null : (
                    <MoreInfoResultCard
                      card={card}
                      collectionId={collectionId}
                      context={null}
                      segment={'tributario'}
                    />
                  )}
                  {collectionId ? (
                    <AddTagDecision
                      cards={[card]}
                      collectionId={collectionId}
                      collectionTags={collectionTags}
                      appearance="secondary"
                      icon={<Tag size="16" color="#84CAFF" />}
                      className="add-tag-container"
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <ResultButtonGroup
            card={card}
            collectionId={collectionId}
            query={query}
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
          />
        </div>
        <div
          className="tv-resultcard_content py-3"
          ref={contentRef}
          style={{
            maxHeight: `${maxHeight}vh`,
            overflowY: showAllText || maxHeight > 55 ? 'auto' : 'hidden',
            transition: 'max-height 0.3s ease',
            overflowX: 'hidden',
          }}
        >
          {sectionsWithoutInteiroTeor.map((section, index) => (
            <div className="pb-3" key={index}>
              {renderSection(section.title, section.content)}
            </div>
          ))}
        </div>

        {card.content.some((section) => section.title === 'Inteiro Teor') && (
          <ResultCardInteiroTeor
            content={
              card.content.find((section) => section.title === 'Inteiro Teor')
                .content
            }
            card={card}
          />
        )}
        <div className="tv-resultcard_footer pt-4">
          {card.timeline && card.timeline !== null ? (
            <Button
              appearance="secondary"
              size="sm"
              onClick={() => setOpenTimeline(!openTimeline)}
              icon={
                !openTimeline ? (
                  <ArrowDown2 size={12} />
                ) : (
                  <ArrowUp2 size={12} />
                )
              }
            >
              Outros acórdãos deste processo
            </Button>
          ) : null}

          <ReadMoreOrLessButton
            showAllText={showAllText}
            /* setShowAllText={showAllText ? handleReduce : handleExpand} */
            setShowAllText={handleToggle}
            cardId={card.id}
            hasMoreText={maxHeight < (contentRef.current?.scrollHeight || 0)}
          />
        </div>
        <div>
          {openTimeline ? <DecisionsTimeline timeline={card.timeline} /> : null}
        </div>
      </div>
    </div>
  );
};
