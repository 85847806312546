import React, { useState, useRef, useEffect } from 'react';
import { Popover, Whisper, Input } from 'rsuite';
import OperatorsHelper from '../OperatorsHelper';
import { requester } from '../../../../../requesters';
import { SearchNormal1, People } from 'iconsax-react';

const OptionList = ({ options, setQuery }) => {
  return (
    <>
      {options.map((option, index) => {
        const Icon =
          option._source.kind === 'collection_history' ? SearchNormal1 : People;
        return (
          <div
            key={option._id || index}
            style={{
              padding: '8px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => setQuery(option.text)}
          >
            <Icon size="18" />
            {option.text}
          </div>
        );
      })}
    </>
  );
};

const SearchInput = ({ queryError, setQuery, query, placeholder }) => {
  const [options, setOptions] = useState([]);
  const searchHistoryRef = useRef(null);

  useEffect(() => {
    setQuery('');
  }, [placeholder, setQuery]);

  const addOperator = (operator) => {
    const inputElement = document.getElementById('home-page-search-input');
    const cursorPosition = inputElement.selectionStart;
    let newQuery = query;

    newQuery =
      operator === '$'
        ? `${newQuery.substring(0, cursorPosition)}${operator}${newQuery.substring(cursorPosition)}`
        : `${newQuery.substring(0, cursorPosition)} ${operator} ${newQuery.substring(cursorPosition)}`;

    newQuery = newQuery.replace('  ', ' ');
    setQuery(newQuery);
    inputElement.focus();
    setTimeout(() => {
      inputElement.setSelectionRange(
        cursorPosition + operator.length + 1,
        cursorPosition + operator.length + 1,
      );
    }, 200);
  };

  const handleChangeQuery = (value) => {
    if (placeholder === 1) {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setQuery(value);
      }
    }

    if (placeholder !== 1) {
      if (/^\d*$/.test(value)) {
        setQuery(value);
      }
    }

    if (value) {
      requester.cards
        .responseSeacrhInput({ query: value })
        .then((response) => setOptions(Array.isArray(response) ? response : []))
        .catch((err) => {
          console.error(err);
          setOptions([]);
        });
    } else {
      setOptions([]);
    }
  };

  const collectionHistoryOptions = options?.filter(
    (option) => option._source.kind === 'collection_history',
  );
  const otherOptions = options.filter(
    (option) => option._source.kind !== 'collection_history',
  );
  const placeholderValue =
    placeholder === 1
      ? 'Quais termos deseja buscar?'
      : 'Digite o número do processo ou do acórdão';

  return (
    <>
      <Whisper
        ref={searchHistoryRef}
        placement="bottom"
        trigger="click"
        speaker={
          placeholder === 1 ? (
            <Popover
              id="search-history-popover"
              style={{
                width: document.getElementById('home-page-search-input')
                  ?.offsetWidth,
              }}
            >
              <div className="search-result">
                <OptionList
                  options={collectionHistoryOptions}
                  setQuery={setQuery}
                />
              </div>

              {collectionHistoryOptions.length > 0 &&
                otherOptions.length > 0 && (
                  <div
                    style={{
                      padding: '4px',
                      color: '#98A2B3',
                      marginLeft: '4px',
                      fontSize: '10px',
                    }}
                  >
                    As pessoas também buscam por
                  </div>
                )}

              <div className="search-result" style={{ color: '#0D91FE' }}>
                <OptionList options={otherOptions} setQuery={setQuery} />
              </div>

              <hr />
              <OperatorsHelper addOperator={addOperator} top={true} />
            </Popover>
          ) : (
            <div></div>
          )
        }
      >
        <Input
          id="home-page-search-input"
          data-testid="simple-home-page-search-input"
          className={`rs-custom-input-big ${queryError ? 'rs-custom-input-error' : ''}`}
          value={query}
          onChange={handleChangeQuery}
          placeholder={placeholderValue}
        />
      </Whisper>
    </>
  );
};

export default SearchInput;
