import { ClipboardClose } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import blueCrossIcon from '../../../../../assets/svgs/blue-cross.svg';
import { Types as BulkactionTypes } from '../../../../../store/ducks/collectionsBulkaction';
import { cn } from '../../../../../lib/utils';

const UndoUnselectMessage = ({ unselectedCards, closeToast }) => {
  const dispatch = useDispatch();

  const UndoUnselectHandler = () => {
    dispatch({ type: BulkactionTypes.UNSELECT_ALL });
    dispatch({ type: BulkactionTypes.SELECT_CARDS, cards: unselectedCards });
    closeToast();
  };

  return (
    <div className="flex items-center justify-between">
      <span className="text-base leading-7 text-[#475467]">
        Decisões descartadas
      </span>
      <span
        className="text-base leading-6 text-[#0069F5] cursor-pointer hover:underline"
        onClick={UndoUnselectHandler}
      >
        Desfazer
      </span>
    </div>
  );
};

export const unselectAllHandler = (dispatch, theCards) => {
  dispatch({ type: BulkactionTypes.UNSELECT_ALL });
  toast.warning(<UndoUnselectMessage unselectedCards={[...theCards]} />, {
    icon: <ClipboardClose size="sm" color="#F79009" />,
    className: 'w-[388px] right-[75px]',
    progressClassName: 'bg-[#F79009]',
    closeButton: false,
  });
};

const UnselectCardsButton = ({ theCards, className }) => {
  const dispatch = useDispatch();
  return (
    <button
      className={cn(
        className,
        'bg-black hover:bg-black hover:opacity-100 text-white font-bold rounded-full min-h-[28px] max-h-[28px] min-w-[98px] max-w-[98px]',
      )}
      onClick={() => unselectAllHandler(dispatch, theCards)}
    >
      <div className="flex flex-row items-center justify-center gap-1">
        <img src={blueCrossIcon} className="w-[10px]" />
        <span className="text-[10px] leading-4 p-0">DESCARTAR</span>
      </div>
    </button>
  );
};

export default UnselectCardsButton;
