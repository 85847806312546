import React, { Component } from 'react';
import { Button as ButtonTuring } from '../../../../../ds-components';
import { requester } from '../../../../../requesters';
import InteiroTeorFilter from './Filters/InteiroTeorFilter';
import FilterWrapper from './FilterWrapper';
import { FiltersSkeleton } from '../../../../../components/Skeleton';
import NewDateFilter from './Filters/NewFilterList/components/NewDateFilter/NewDateFilter';
import TaxFilters from './Filters/NewTaxFilters/TaxFilters';
import { NewFilterList } from './Filters/NewFilterList/NewFilterList';
import { getResultById } from './Filters/NewFilterList/FilterPath';

class FiltersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trigger: false,
      filters: null,
      requesting: true,
      selectedDecisionOptions: [],
      selectedTypeOptions: [],
      selectedFilters: {}, // Altera o selectedFilters para objeto
      appliedFilterId: null,
      dateRange: { start: null, end: null },
    };
  }

  componentDidMount() {
    this.getFilters();
    this.getResultByIdAndHandle();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.appliedFilterId !== this.state.appliedFilterId) {
      this.getResultByIdAndHandle();
    }
  }

  getFilters() {
    if (!this.props.filters) {
      this.setState({ requesting: true });
      requester.cards
        .getAllFilters()
        .then((response) => {
          const filtersResp = response;
          let defaultFilters = {};

          this.setState({ filters: filtersResp });

          filtersResp.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });
          this.props.filtersProps?.setOriginalFilters(filtersResp);
          this.props.filtersProps?.setDefaultFilters(defaultFilters);
          this.props.filtersProps?.loadFilters();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.setState({ requesting: false });
        });
    }
  }

  getResultByIdAndHandle = async () => {
    const { appliedFilterId } = this.state;

    if (!appliedFilterId) return;

    try {
      const result = await getResultById(appliedFilterId);
      this.setState({ resultById: result });
    } catch (error) {
      console.error('Erro ao obter o resultado por ID:', error);
    }
  };

  // Função para limpar os filtros
  clearSelectedFilters = () => {
    // Limpa os checkboxes no estado
    this.setState({
      selectedDecisionOptions: [],
      selectedTypeOptions: [],
      selectedFilters: {}, // Limpa todos os filtros
      appliedFilterId: null,
    });
  };

  updateSelectedFilters = (updatedValues) => {
    this.setState((prevState) => ({
      selectedFilters: {
        ...prevState.selectedFilters,
        ...updatedValues,
      },
    }));
  };

  handleDecisionOptionChange = (id) => {
    this.setState((prevState) => {
      const selectedDecisionOptions =
        prevState.selectedDecisionOptions?.includes(id)
          ? prevState.selectedDecisionOptions.filter((option) => option !== id)
          : [...prevState.selectedDecisionOptions, id];
      return { selectedDecisionOptions };
    });
  };

  handleTypeOptionChange = (id) => {
    this.setState((prevState) => {
      const selectedTypeOptions = prevState.selectedTypeOptions.includes(id)
        ? prevState.selectedTypeOptions.filter((option) => option !== id)
        : [...prevState.selectedTypeOptions, id];

      return { selectedTypeOptions };
    });
  };

  handleStateUpdate = (newState) => {
    this.setState(newState);
  };

  setDateRange(newDateRange) {
    this.setState({ dateRange: newDateRange });
  }

  setTrigger() {
    this.setState({ trigger: !this.state.trigger });
  }

  render() {
    const {
      filters,
      requesting,
      selectedFilters,
      resultById,
      dateRange,
      trigger,
    } = this.state;
    const {
      filtersProps,
      tmpFilters,
      entities,
      additionalProp1,
      additionalProp2,
      setValueStyles,
      scrolledDown,
      query,
    } = this.props;
    this.setDateRange = this.setDateRange.bind(this);

    const isQueryEmpty = !query || query.trim() === '';
    return (
      <div
        className="tv-filters_list"
        style={{
          width: '400px',
          padding: '5px',
          transition: 'width 0.6s ease',
        }}
      >
        {requesting ? (
          <FiltersSkeleton />
        ) : (
          <div className="tv-filters_body">
            <NewFilterList
              selectedFilters={selectedFilters}
              updateSelectedFilters={this.updateSelectedFilters}
              filtersProps={filtersProps}
              setValueStyles={setValueStyles}
              search={this.props.search}
              setNewValue={this.props.setNewValue}
              setValitadionFilter={this.props.setValitadionFilter}
              handleStateUpdate={this.handleStateUpdate}
              setClearFiltro={this.props.setClearFiltro}
              trigger={this.state.trigger}
              setTrigger={this.setTrigger}
            />
            <InteiroTeorFilter
              context="Results"
              filtersProps={filtersProps}
              updateSelectedFilters={this.updateSelectedFilters}
              resultById={resultById?.where_search}
            />
            <NewDateFilter
              filters={filters}
              filtersProps={filtersProps}
              tmpFilters={tmpFilters}
              entities={entities}
              additionalProp1={additionalProp1}
              additionalProp2={additionalProp2}
              selectedFilters={selectedFilters}
              updateSelectedFilters={this.updateSelectedFilters}
              endDate={resultById?.end_date}
              startDate={resultById?.start_date}
              dateRange={dateRange}
              setDateRange={this.setDateRange}
              trigger={this.state.trigger}
            />
            <form className="tv-filters_content">
              {filters
                ? filters.map((filter, index) => (
                    <FilterWrapper
                      key={index}
                      context="Results"
                      filter={filter}
                      tmpFilters={tmpFilters}
                      entities={entities}
                      filtersProps={filtersProps}
                      selectedFilters={selectedFilters}
                      updateSelectedFilters={this.updateSelectedFilters}
                      resultById={resultById}
                    />
                  ))
                : null}
            </form>
          </div>
        )}
        <TaxFilters
          filters={filters}
          onDecisionOptionChange={this.handleDecisionOptionChange}
          onTypeOptionChange={this.handleTypeOptionChange}
          selectedFilters={selectedFilters}
          updateSelectedFilters={this.updateSelectedFilters}
          resultById={resultById}
        />
        <div className="tv-filters_footer">
          <ButtonTuring
            size="md"
            fullWidth
            onClick={() =>
              filtersProps.saveFilters(
                filtersProps.history,
                filtersProps.match,
                encodeURIComponent(query || filtersProps.query),
              )
            }
            disabled={isQueryEmpty}
            style={{
              opacity: isQueryEmpty ? 0.5 : 1,
              cursor: isQueryEmpty ? 'not-allowed' : 'pointer',
            }}
          >
            Aplicar filtro
          </ButtonTuring>
        </div>
      </div>
    );
  }
}

export default FiltersList;
