import { createActions, createReducer } from 'reduxsauce';

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  selectCards: ['cards'],
  unselectCards: ['cards'],
  unselectAll: [],
});

/*
 * Handlers
 */
const INITIAL_STATE = {
  selectedCards: [],
};

const selectCards = (state = INITIAL_STATE, action) => {
  const { cards } = action;
  return {
    ...state,
    selectedCards: [...state.selectedCards, ...cards],
  };
};

const unselectCards = (state = INITIAL_STATE, action) => {
  const { cards } = action;
  return {
    ...state,
    selectedCards: state.selectedCards.filter((selectedCard) =>
      cards.every((card) => card.id !== selectedCard.id),
    ),
  };
};

const unselectAll = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selectedCards: [],
  };
};

/*
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SELECT_CARDS]: selectCards,
  [Types.UNSELECT_CARDS]: unselectCards,
  [Types.UNSELECT_ALL]: unselectAll,
});
