import React from 'react';

const EmptyCollection = () => {
  return (
    <div className="flex flex-col items-center mt-8 w-full">
      <img
        style={{ width: 300 }}
        className="mb-4"
        alt="Coleção vazia"
        src="/empty_collection.png"
      />
      <p>Você ainda não adicionou nenhum resultado à sua Coleção</p>
      <p className="font-bold">
        Que tal fazer uma nova pesquisa sobre esse tema?
      </p>
    </div>
  );
};

export default EmptyCollection;
