import { useExportReports } from '../../../../../../store/ducks/exportReports';
import { cn } from '../../../../../../lib/utils';
import { Colorfilter, InfoCircle } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { toast } from 'react-toastify';

type ChooseTextColorProps = {
  text_color?: string;
};

export const ChooseTextColor = ({ text_color }: ChooseTextColorProps) => {
  const [color, setColor] = useState(text_color || '');
  const { setReport, error, setError } = useExportReports();

  const handleColor = (_color: string) => {
    setColor(_color);
    setError({ text_color: false });
    setReport({ text_color: _color });
    toast.success('Cor do texto alterada com sucesso');
  };

  useEffect(() => {
    if (text_color) setColor(text_color);
  }, [text_color]);

  return (
    <div className="flex">
      <div className="flex flex-col relative">
        <div className="flex">
          <Colorfilter size={20} color="#0D91FE" />
          <span className="ml-3 mr-1">Escolher cor das letras</span>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={
              <Tooltip>
                Escolha a cor das letras que deem contrastecom a cor da sua
                marca
              </Tooltip>
            }
          >
            <InfoCircle size="16" color="#0D91FE" />
          </Whisper>
        </div>
        {error.text_color && (
          <span className="absolute -bottom-4 left-8 text-[10px] text-[#F04438]">
            Escolha uma das cores
          </span>
        )}
      </div>

      <div className="ml-auto gap-[10px] flex items-center">
        <Whisper
          trigger="hover"
          placement="top"
          speaker={
            <Tooltip>
              {color === '#ffffff' ? 'Cor selecionada' : 'Branco'}
            </Tooltip>
          }
        >
          <div
            className={cn(
              'w-[26px] h-[26px] rounded border border-solid border-[#D0D5DD] cursor-pointer',
              'hover:border-blue-300',
              color === '#ffffff'
                ? 'border-blue-500 hover:border-blue-500'
                : '',
            )}
            onClick={() => handleColor('#ffffff')}
          />
        </Whisper>

        <Whisper
          trigger="hover"
          placement="top"
          speaker={
            <Tooltip>
              {color === '#000000' ? 'Cor selecionada' : 'Preto'}
            </Tooltip>
          }
        >
          <div
            className={cn(
              ' w-[26px] h-[26px] rounded border border-solid border-[#D0D5DD] bg-black cursor-pointer',
              'hover:border-blue-300',
              color === '#000000'
                ? 'border-blue-500 hover:border-blue-500'
                : '',
            )}
            onClick={() => handleColor('#000000')}
          />
        </Whisper>
      </div>
    </div>
  );
};
