import { Notification } from 'iconsax-react';
import { Whisper, Tooltip } from 'rsuite';

const NotificationOpenButton = ({ searchPage, unread, onClick }) => {
  return (
    <div className="notification-wrapper">
      <Whisper
        placement="bottom"
        trigger="hover"
        speaker={<Tooltip>Suas notificações</Tooltip>}
      >
        <div
          onClick={onClick}
          data-testid="open-notifications"
          className="notifications-unread"
          style={{ cursor: 'pointer', marginRight: searchPage ? 0 : 10 }}
        >
          {unread ? (
            <div className="badge-unread">
              <span>{unread > 99 ? '+99' : unread}</span>
            </div>
          ) : null}
          <Notification size="18" color="#0069F5" />
        </div>
      </Whisper>
    </div>
  );
};

export default NotificationOpenButton;
