import { MsalProvider, useMsal } from '@azure/msal-react';
import {
  Configuration,
  PublicClientApplication,
  PopupRequest,
} from '@azure/msal-browser';
import { useCallback, useEffect } from 'react';
import { useOauthMicrosoft } from '../api/auth';
import { toast } from 'react-toastify';
import { useOauthMicrosoftStore } from '../store/ducks/oauthMicrosoft';

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID as string,
    authority: process.env.REACT_APP_MICROSOFT_AUTHORITY as string,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

const pca = new PublicClientApplication(configuration);

const SocialLoginMicrosoftButton = () => {
  const { instance } = useMsal();
  const { mutateAsync } = useOauthMicrosoft();
  const { setCode, setEmail } = useOauthMicrosoftStore();

  const handleLoginPopup = useCallback(() => {
    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: '/login',
        prompt: 'select_account',
      })
      .then((data) => {
        return mutateAsync({ email: data.account.username });
      })
      .then((data) => {
        setCode(data.data.code);
        setEmail(data.data.email);
      })
      .catch((_) => {
        console.log(_);
        toast.error('Email não cadastrado na Turivius, tente outro email.', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  }, []);

  useEffect(() => {
    instance.clearCache();
  }, []);

  return (
    <button className="turivius-button with-icon" onClick={handleLoginPopup}>
      <img src="Icons/Microsoft.png" />
      Continuar com Microsoft
    </button>
  );
};

export const MicrosoftSocialLoginProvider = () => (
  <MsalProvider instance={pca}>
    <SocialLoginMicrosoftButton />
  </MsalProvider>
);
