import { request } from './requester';
import { Posthog } from '../posthog/posthog';

function addCardsToCollection(userId, collectionId, cardsIds, etContext) {
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}/card/add/`,
    auth: 'token',
    contentType: 'application/json',
    data: { cards: cardsIds },
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'save_to_collection',
      ids: cardsIds,
      to: collectionId,
    });
    return response;
  });
}

function removeCardsFromCollection(userId, collectionId, cardsIds, etContext) {
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}/card/remove/`,
    auth: 'token',
    contentType: 'application/json',
    data: { cards: cardsIds },
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'remove_from_collection',
      id: cardsIds,
      to: collectionId,
    });
    return response;
  });
}

function addCardsToReadLater(userId, cardsIds, etContext) {
  return request({
    type: 'POST',
    microservice: 'collections',
    action: `boards/${userId}/readlater/card/add/`,
    auth: 'token',
    contentType: 'application/json',
    data: { cards: cardsIds },
  }).then((response) => {
    // Posthog.capture(etContext, {
    //   action: 'save_to_collection',
    //   ids: cardsIds,
    //   to: collectionId,
    // });
    return response;
  });
}

async function fetchCollections(userId, page = 1) {
  return request({
    type: 'GET',
    microservice: 'collections',
    action: `boards/${userId}?include_cards=true&page=${page}`,
    auth: 'token',
  }).then((collection) => {
    return collection;
  });
}

function createCollection(userId, name, description = '', etContext) {
  return request({
    type: 'POST',
    microservice: 'collections',
    action: `boards/${userId}`,
    auth: 'token',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: {
      name,
      description,
    },
  }).then((collection) => {
    Posthog.capture(etContext, {
      action: 'create_collection',
      id: collection.id,
    });
    return collection;
  });
}

function deleteCollection(userId, collectionId, etContext) {
  return request({
    type: 'DELETE',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}`,
    auth: 'token',
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'remove_collection',
      id: collectionId,
    });
    return response;
  });
}

function getUserCollections(userId, page, includeCards = false) {
  return request({
    type: 'GET',
    microservice: 'collections',
    action: `boards/${userId}`,
    auth: 'token',
    data: {
      page,
      include_cards: includeCards,
    },
  }).then((collections) => {
    return collections;
  });
}

function getCardsCollection(userId, collectionId, page = 1) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `cards/${userId}/${collectionId}?page=${page}`,
    auth: 'token',
  }).then((cards) => {
    return cards;
  });
}

function getCollectionComments(collectionId) {
  return request({
    type: 'GET',
    microservice: 'collections',
    action: `boards/${collectionId}/comment/`,
    auth: 'token',
  }).then((comments) => {
    return comments;
  });
}

function addCollectionComment(userId, collectionId, text, cardId) {
  return request({
    type: 'POST',
    microservice: 'collections',
    action: `boards/${collectionId}/comment/`,
    auth: 'token',
    data: {
      board: collectionId,
      text,
      card_id: cardId,
      user_id: userId,
    },
  }).then((comment) => {
    Posthog.capture('AddCommentToCollection', {
      action: 'add_comment_to_collection',
      id: collectionId,
      to: cardId,
    });
    return comment;
  });
}

function removeCollectionComment(collectionId, commentId) {
  return request({
    type: 'DELETE',
    microservice: 'collections',
    action: `boards/${collectionId}/comment/${commentId}`,
    auth: 'token',
  }).then((response) => {
    Posthog.capture('RemoveCommentFromCollection', {
      action: 'remove_comment_from_collection',
      id: collectionId,
      to: null,
    });
    return response;
  });
}

function getCollection(userId, collectionId) {
  return request({
    type: 'GET',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}`,
    auth: 'token',
  }).then((collection) => {
    return collection;
  });
}

function updateCollection(userId, collectionId, name, description, etContext) {
  const dataRequest = [
    {
      op: 'replace',
      path: `/name`,
      value: name,
    },
    {
      op: 'replace',
      path: `/description`,
      value: description,
    },
  ];
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}`,
    auth: 'token',
    contentType: 'application/json',
    data: dataRequest,
  }).then((collection) => {
    Posthog.capture(etContext, {
      action: 'update_collection',
      id: collectionId,
    });
    return collection;
  });
}

function addUsersToCollection(userId, collectionId, usersIds, etContext) {
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}/user/add/`,
    auth: 'token',
    contentType: 'application/json',
    data: { users: usersIds },
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'share_collection',
      ids: usersIds,
      to: collectionId,
    });
    return response;
  });
}

function removeUsersFromCollections(userId, collectionId, usersIds, etContext) {
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}/user/remove/`,
    auth: 'token',
    contentType: 'application/json',
    data: { users: usersIds },
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'unshare_collection',
      ids: usersIds,
      to: collectionId,
    });
    return response;
  });
}

function shareWithCompany(
  userId,
  collectionId,
  visibilityToCompany,
  etContext,
) {
  const dataRequest = [
    {
      op: 'replace',
      path: `/public_to_company`,
      value: visibilityToCompany,
    },
  ];
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `boards/${userId}/${collectionId}`,
    auth: 'token',
    contentType: 'application/json',
    data: dataRequest,
  }).then((response) => {
    Posthog.capture(etContext, {
      action: visibilityToCompany
        ? 'share_collection_with_company'
        : 'unshare_collection_with_company',
      ids: null,
      to: collectionId,
    });
    return response;
  });
}
/* Search Collections */

function searchCollections(group, searcher, page, ordering, limit = 12) {
  let offset = page * limit - limit;

  const collections = request({
    type: 'GET',
    microservice: 'collections',
    action: `api/${group}?search=${searcher}&ordering=${ordering}&offset=${offset}`,
    auth: 'token',
  });
  return collections;
}

function matchResultsThatHaveCollections(cards) {
  const collections = request({
    type: 'GET',
    microservice: 'collections',
    action: `api/in_collection?cards=${cards}`,
    auth: 'token',
  });
  return collections;
}

/*Tags*/
function createTag(title, board, userId) {
  return request({
    type: 'POST',
    microservice: 'collections',
    action: 'api/tags/',
    auth: 'token',
    data: {
      title: title,
      board: board,
      user_id: userId,
    },
  }).then((response) => {
    return response;
  });
}

function getTagsByCollection(board) {
  return request({
    type: 'GET',
    microservice: 'collections',
    action: `api/tags?board=${board}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function addCardsToTag(tagId, cards) {
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `api/tags/${tagId}/`,
    auth: 'token',
    data: {
      cards: cards,
    },
  }).then((response) => {
    return response;
  });
}

function removeCardsToTag(tagId, cards) {
  return request({
    type: 'PATCH',
    microservice: 'collections',
    action: `api/tags/${tagId}/remove/`,
    auth: 'token',
    data: {
      cards: cards,
    },
  }).then((response) => {
    return response;
  });
}
function deleteTag(tagId) {
  return request({
    type: 'DELETE',
    microservice: 'collections',
    action: `api/tags/${tagId}/`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

export const collections = {
  addCardsToCollection,
  removeCardsFromCollection,
  addCardsToReadLater,
  createCollection,
  deleteCollection,
  getCardsCollection,
  getUserCollections,
  getCollectionComments,
  addCollectionComment,
  removeCollectionComment,
  getCollection,
  updateCollection,
  addUsersToCollection,
  removeUsersFromCollections,
  shareWithCompany,
  searchCollections,
  matchResultsThatHaveCollections,
  createTag,
  getTagsByCollection,
  addCardsToTag,
  removeCardsToTag,
  deleteTag,
  fetchCollections,
};
