import { useEffect } from 'react';
import { Toggle } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import './SynonymsFilter.scss';

const SynonymsFilter = ({ context, filtersProps }) => {
  function onChangeFilter(option, type) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: type,
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    switch (type) {
      case 'synonyms':
        filtersProps?.changeSynonyms(option);
        break;
      case 'recursosRepetitivos':
        filtersProps?.changeRecursosRepetitivos(option);
        break;
      case 'repercussaoGeral':
        filtersProps?.changeRepercussaoGeral(option);
        break;
      default:
        break;
    }
  }

  useEffect(() => {}, [filtersProps]);

  return (
    <div className="synonyms-filter-styles">
      <div className="toggle-section">
        <label className="synonyms-toggle-label">
          Incluir Termos Semelhantes
        </label>
        <Toggle
          onChange={(value) => onChangeFilter(value, 'synonyms')}
          checked={filtersProps?.synonyms}
          disabled={false}
        />
      </div>
      <hr />
      <div className="toggle-group">
        <label>Buscar apenas</label>
        <div className="toggle-item">
          <span>Recursos Repetitivos</span>
          <Toggle
            onChange={(value) => onChangeFilter(value, 'recursosRepetitivos')}
            checked={false}
            disabled={false}
          />
          <span className="toggle-counter">0</span>
        </div>
        <div className="toggle-item">
          <span>Repercussão Geral</span>
          <Toggle
            onChange={(value) => onChangeFilter(value, 'repercussaoGeral')}
            checked={false}
            disabled={false}
          />
          <span className="toggle-counter">0</span>
        </div>
      </div>
    </div>
  );
};

export default SynonymsFilter;
