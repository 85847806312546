import { useEffect } from 'react';
import CollapsableSelect from '../Inputs/CollapsableSelect';
import MultiSelect from '../Inputs/MultiSelect';

const FilterWrapper = ({
  filter,
  context,
  filtersProps,
  entities,
  updateSelectedFilters,
  resultById,
}) => {
  const renderFilterType = (filterType) => {
    switch (filterType) {
      case 'multiple_autocomplete':
        return (
          <MultiSelect
            label={filter.public_name}
            filter={filter}
            filterReference={filter.field_name}
            filtersProps={filtersProps}
            placeholder={'Filtre por relatores'}
            context={context}
            name={'relator'}
            updateSelectedFilters={updateSelectedFilters}
            resultById={resultById?.by_relator}
          />
        );
      case 'multiple':
        return (
          <CollapsableSelect
            label={filter.public_name}
            filtersProps={filtersProps}
            context={context}
            filter={filter}
            filterReference={'entities'}
            labelKey="entities"
            options={entities}
            updateSelectedFilters={updateSelectedFilters}
            resultById={resultById?.entities}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    renderFilterType(filter.type);
  }, []);

  return renderFilterType(filter.type) ? (
    <div className="tv-filter_group">{renderFilterType(filter.type)}</div>
  ) : null;
};

export default FilterWrapper;
