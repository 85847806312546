import axios from 'axios';

const API_BASE_URL =
  'https://cards-showroom-3lq7qiwneq-rj.a.run.app/filterResults';

export const getAllResults = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar todos os resultados:', error);
    throw error;
  }
};

export const getResultById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createResult = async (newData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/`, newData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateResult = async (id, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/${id}/`, updatedData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteResult = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
