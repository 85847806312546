import { useMemo } from 'react';
import { Dropdown } from 'rsuite';
import { Button } from '../../../../../ds-components';
import { Setting2 } from 'iconsax-react';
import './CollectionHeader.scss';
import { exportCollectionToPDF } from '../../../../../services/collections';
import ManagePeopleCollectionButton from '../ManagePeopleCollectionButton/ManagePeopleCollectionButton';
import ShareCollectionWithCompanyButton from '../ShareCollectionWithCompanyButton/ShareCollectionWithCompanyButton';
import EditCollectionButton from '../EditCollectionButton/EditCollectionButton';
import RemoveCollectionButton from '../RemoveCollectionButton/RemoveCollectionButton';
import { updateCheckllist } from '../../../../../services/checklist';
import { ExportReport } from './ExportReport';

type CollectionHeaderProps = {
  collection: any;
  reloadAction: any;
};

const renderToggleMenu = (props: any, ref: any) => {
  return (
    <Button
      {...props}
      ref={ref}
      appearance="secondary"
      size="sm"
      icon={<Setting2 size={16} color="#475467" />}
      tooltipText="Opções"
    >
      Opções
    </Button>
  );
};

const CollectionHeader = ({
  collection,
  reloadAction,
}: CollectionHeaderProps) => {
  const updTour = async () => {
    const check = JSON.parse(localStorage.getItem('checklist') as string);
    if (!check.trail.report) {
      await updateCheckllist(check.id, 'report');
    }
  };

  const handleClickExport = async (collectionId: any, isDefault: boolean) => {
    try {
      await exportCollectionToPDF(
        userId,
        collectionId,
        collection.name,
        isDefault,
      );
      updTour();
    } catch (error) {
      console.error(error);
    }
  };

  const userId = useMemo(() => localStorage.getItem('user'), []);

  if (!collection) return <div></div>;

  return (
    <div className="collection-header">
      <div className="collection-header_description">
        <h6 className="font-semibold">Descrição:</h6>
        {collection.description !== '' ? (
          <p className="description">{collection.description}</p>
        ) : (
          <p className="no-description">
            nenhuma descrição adicionada a essa coleção
          </p>
        )}
      </div>

      <div className="collection-header_actions">
        <Dropdown
          placement="leftStart"
          renderToggle={renderToggleMenu}
          id="nav-menu-options"
        >
          <Dropdown.Item>
            <ManagePeopleCollectionButton
              //@ts-ignore
              collection={collection}
              collectionId={collection.id}
              sharedWith={collection.shared_with}
              context={'Single Collection'}
              onSearch={reloadAction}
              style={{ width: 'auto' }}
            />
          </Dropdown.Item>
          <Dropdown.Item>
            <ShareCollectionWithCompanyButton
              //@ts-ignore
              publicToCompany={collection.public_to_company}
              collection={collection}
              collectionId={collection.id}
              collectionTitle={collection.name}
              context={'Single Collection'}
              onSearch={reloadAction}
              style={{
                margin: 'auto',
                padding: '14px 4px',
              }}
            />
          </Dropdown.Item>
          <Dropdown.Item>
            <EditCollectionButton
              collectionId={collection.id}
              style={{ width: '100%' }}
              collectionTitle={collection.name}
              collectionDescription={collection.description}
              context={'Single Collection'}
              onSearch={reloadAction}
            />
          </Dropdown.Item>
          {parseInt(collection.owner.id) ===
          parseInt(localStorage.getItem('user') as string) ? (
            <Dropdown.Item>
              <RemoveCollectionButton
                //@ts-ignore
                collectionId={collection.id}
                style={{ width: '100%' }}
                context={'Single Collection'}
                onSearch={reloadAction}
              />
            </Dropdown.Item>
          ) : null}
        </Dropdown>

        <ExportReport handleClickExport={handleClickExport} />
      </div>
    </div>
  );
};

export default CollectionHeader;
