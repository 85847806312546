import { useState, useEffect } from 'react';
import './TaxFilters.scss';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

const TaxFilters = ({
  filters,
  onDecisionOptionChange,
  onTypeOptionChange,
  updateSelectedFilters,
  resultById,
}) => {
  const [isDecisionOpen, setIsDecisionOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);

  const [selectedDecisionOptions, setSelectedDecisionOptions] = useState([]);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);

  const toggleDecision = () => setIsDecisionOpen(!isDecisionOpen);
  const toggleType = () => setIsTypeOpen(!isTypeOpen);

  const filterByVote = (filters && filters[4]) || {};
  const filterCarfFild = (filters && filters[6]) || {};

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem('filters'));
    if (local) {
      setSelectedDecisionOptions(local.by_vote?.map((item) => item));
      setSelectedTypeOptions(local.by_carf?.map((item) => item));
    }
  }, []);

  const updateURLParams = (paramName, values) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const filters = JSON.parse(params.get('filters') || '{}');
      filters[paramName] = values;
      params.set('filters', JSON.stringify(filters));
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`,
      );
    } catch (error) {
      console.error('Erro ao atualizar os parâmetros da URL:', error);
    }
  };

  const handleCheckboxChange = (id, filterType) => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);
    console.log(selectedDecisionOptions, 'selectedDecisionOptions');
    if (filterType === 'decision') {
      const newSelected = selectedDecisionOptions
        ? selectedDecisionOptions?.includes(id)
          ? selectedDecisionOptions.filter((option) => option !== id)
          : [...selectedDecisionOptions, id]
        : [id];
      console.log(newSelected, 'newSelected');
      setSelectedDecisionOptions(newSelected);
      updateSelectedFilters({ decision: newSelected });
      onDecisionOptionChange?.(id);

      filtersLocal.by_vote = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('by_vote', newSelected);
    } else if (filterType === 'type') {
      console.log(selectedTypeOptions, 'selectedTypeOptions');
      const newSelected =
        selectedTypeOptions && selectedTypeOptions.length > 0
          ? selectedTypeOptions?.includes(id)
            ? selectedTypeOptions.filter((option) => option !== id)
            : [...selectedTypeOptions, id]
          : [id];

      setSelectedTypeOptions(newSelected);
      updateSelectedFilters({ type: newSelected });
      onTypeOptionChange?.(id);

      filtersLocal.by_carf = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('by_carf', newSelected);
    }
  };

  useEffect(() => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);
    const params = new URLSearchParams(window.location.search);
    const filtersFromUrl = JSON.parse(params.get('filters') || '{}');

    if (filtersFromUrl.by_vote) {
      setSelectedDecisionOptions(filtersLocal.by_vote);
    }

    if (filtersFromUrl.by_carf) {
      setSelectedTypeOptions(filtersLocal.by_carf);
    }
    if (resultById?.by_vote) {
      setSelectedDecisionOptions(resultById.by_vote);
    }

    if (resultById?.by_carf) {
      setSelectedTypeOptions(resultById.by_carf);
    }
  }, [resultById]);

  return (
    <div className="tax-filters">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="filters-title">Filtros Tributários</div>
      </div>
      <div className="filter-header" onClick={toggleDecision}>
        <span style={{ display: 'flex', gap: '10px' }}>
          {isDecisionOpen ? (
            <ArrowUp2 color="#98A2B3" />
          ) : (
            <ArrowDown2 color="#98A2B3" />
          )}
          {filterByVote.public_name || 'Resultado da decisão'}
        </span>
        <span className="filter-count">
          {filterByVote.options?.length || 0}
        </span>
      </div>
      {isDecisionOpen && (
        <>
          {filterByVote.options?.map((item, index) => (
            <div className="filter-options" key={index}>
              <label>
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectedDecisionOptions?.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id, 'decision')}
                />
                <span style={{ width: '100%' }}>{item.name}</span>
                <span className="option-count">
                  {item.count ? item.count : '0'}
                </span>{' '}
              </label>
            </div>
          ))}
        </>
      )}

      <div className="filter-header" onClick={toggleType}>
        <span style={{ display: 'flex', gap: '10px' }}>
          {isTypeOpen ? (
            <ArrowUp2 color="#98A2B3" />
          ) : (
            <ArrowDown2 color="#98A2B3" />
          )}
          {filterCarfFild.public_name || 'Tipo de decisão (CARF)'}
        </span>
        <span className="filter-count">
          {filterCarfFild.options?.length || 0}
        </span>
      </div>
      {isTypeOpen && (
        <>
          {filterCarfFild.options?.map((item) => (
            <div className="filter-options" key={item.id}>
              <label>
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectedTypeOptions?.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id, 'type')}
                />
                {item.name}
                <span className="option-count">
                  {item.count ? item.count : '0'}
                </span>{' '}
              </label>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TaxFilters;
