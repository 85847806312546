import React from 'react';
import {
  Input,
  InputGroup,
  Message,
  Grid,
  Row,
  Col,
  toaster,
  Whisper,
  Popover,
  Button,
} from 'rsuite';
import { requester } from '../../../../../requesters';
import { withRouter } from 'react-router-dom';
import OperatorsHelper from '../OperatorsHelper';
import { SearchNormal1, People, ArrowDown2, CloseSquare } from 'iconsax-react';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      filters: '',
      options: [],
      isSearchByNumber: false,
      showSearchTypeOptions: false,
      buttonTitle: 'Buscar',
    };
    this.searchInputRef = React.createRef();
    this.searchTypeRef = React.createRef();
    this.addOperator = this.addOperator.bind(this);
  }

  handleChangeQuery = (value) => {
    if (!this.state.isSearchByNumber) {
      const validValue = value.replace(/[^a-zA-Z\s]/g, '');
      this.setState({ query: validValue });
      if (validValue) {
        requester.cards
          .responseSeacrhInput({ query: validValue })
          .then((response) =>
            this.setState({ options: Array.isArray(response) ? response : [] }),
          )
          .catch((err) => {
            console.error(err);
            this.setState({ options: [] });
          });
      } else {
        this.setState({ options: [] });
      }
    } else {
      this.setState({ query: value });
      if (value) {
        requester.cards
          .responseSeacrhInput({ query: value })
          .then((response) =>
            this.setState({ options: Array.isArray(response) ? response : [] }),
          )
          .catch((err) => {
            console.error(err);
            this.setState({ options: [] });
          });
      } else {
        this.setState({ options: [] });
      }
    }
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.doSearch();
    }
  };

  doSearch = () => {
    setTimeout(() => {
      let { query } = this.state;

      if (query === null || query === undefined) {
        query = this.searchInputRef.current.value;
        this.setState({ query });
      }

      if (query.length < 3) {
        toaster.push(
          <Message duration={4000} type="warning" showIcon closable>
            Sua busca deve ter pelo menos três caracteres
          </Message>,
        );
      } else if (this.state.query.toLowerCase().includes(' adj ')) {
        toaster.push(
          <Message
            position={'bottom'}
            header="O operador ADJ deve vir acompanhado de um número"
            duration={0}
            type="warning"
            showIcon
            closable
          >
            <div style={{ maxWidth: 320 }}>
              <Grid fluid>
                <Row>
                  <Col xs={24}>
                    <p>
                      <b>Exemplo</b>
                      <br />
                      <i>
                        multa <b>ADJ2</b> ofício
                      </i>{' '}
                      irá retornar os resultados em que{' '}
                      <u> ofício será no máximo a segunda palavra após multa</u>
                      .
                    </p>
                  </Col>
                  <Col xs={24}>
                    <blockquote>
                      [...] não pode ser exigida cumulativamente com a{' '}
                      <mark>multa</mark> de <mark>ofício</mark>, aplicável [...]
                    </blockquote>
                  </Col>
                </Row>
              </Grid>
            </div>
          </Message>,
        );
      } else {
        if (!this.props.filtersProps.filters) {
          this.props.filtersProps.loadFilters();
        } else {
          this.props.filtersProps.saveFilters(
            this.props.history,
            this.props.match,
            encodeURIComponent(this.state.query),
          );
          this.props.filtersProps.setQuery(encodeURIComponent(query));
        }
      }
    }, 100);
  };

  verifyAndGetFilters = () => {
    if (
      this.props.originalFilters &&
      Object.keys(this.props.originalFilters).length === 0
    ) {
      requester.cards
        .getFilters()
        .then((response) => {
          const filters = response;
          let defaultFilters = {};
          let entityFilter = {};
          filters.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
              entityFilter = { ...filter };
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });
          this.props.filtersProps.setOriginalFilters(filters);
          this.props.filtersProps.setDefaultFilters(defaultFilters);
          this.props.filtersProps.loadFilters();
          this.props.filtersProps.restoreOptionalFilters();
          this.setState({ filter: entityFilter });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (this.mounted) {
            this.setState({ requesting: false });
          }
        });
    }
  };

  addOperator(operator) {
    let { query } = this.state;
    if (operator === '$') {
      query = `${query}${operator.trim()}`.replace('  ', ' ');
    } else {
      query = `${query} ${operator} `.replace('  ', ' ');
    }

    this.setState({ query });
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  }

  handlePopoverSelect = (title) => {
    this.setState({
      buttonTitle: title,
      isSearchByNumber: title === 'Buscar por Número',
      query: '',
    });
    if (this.searchTypeRef.current) {
      this.searchTypeRef.current.close();
    }
  };

  handleClearQuery = () => {
    this.setState({ query: '' });
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  };

  componentDidMount() {
    this.verifyAndGetFilters();
    this.mounted = true;
    if (this.props.location.search !== '') {
      const params = new URLSearchParams(this.props.location.search);
      const query = params.get('query');
      this.setState({ query });
      this.props.filtersProps.setQuery(query);
    }

    this.props.history.listen((location) => {
      if (this.mounted) {
        if (
          location.pathname.includes('resultados') &&
          location.search !== ''
        ) {
          const params = new URLSearchParams(location.search);
          const query = params.get('query');
          this.setState({ query });
        }
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { style, salueStyles } = this.props;
    const { query, options, buttonTitle, isSearchByNumber } = this.state;

    const OptionList = ({ options, setQuery }) => {
      return (
        <>
          {options.map((option, index) => {
            const Icon =
              option._source.kind === 'collection_history'
                ? SearchNormal1
                : People;
            return (
              <div
                key={option._id || index}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => setQuery(option.text)}
              >
                <Icon size="18" />
                {option.text}
              </div>
            );
          })}
        </>
      );
    };

    const collectionHistoryOptions = options?.filter(
      (option) => option._source.kind === 'collection_history',
    );
    const otherOptions = options.filter(
      (option) => option._source.kind !== 'collection_history',
    );

    const stylesValues = {
      backgroundColor: '#ffffff',
      width: '80%',
      zIndex: salueStyles ? 0 : 100,
      height: '80px',
      paddingTop: '18px',
      paddingLeft: '30px',
    };

    return (
      <div style={stylesValues}>
        <div id="search-bar-container">
          <div
            className="input-field "
            style={{ display: 'flex', ...style, flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '98%',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              >
                <InputGroup inside size="lg" className="search-bar-result ">
                  <Button
                    id="btn-search"
                    onClick={this.doSearch}
                    className="menu-button"
                    style={{ minWidth: '80px' }}
                  >
                    <span>{buttonTitle}</span>
                  </Button>
                  <Whisper
                    ref={this.searchTypeRef}
                    placement="bottomStart"
                    trigger="click"
                    speaker={
                      <Popover
                        id={'search-type-popover'}
                        className="custom-popover"
                        style={{
                          width: 270,
                          border: '1px solid #E4E7EC',
                          borderRadius: '4px',
                        }}
                      >
                        <div
                          style={{ padding: '8px', cursor: 'pointer' }}
                          onClick={() => this.handlePopoverSelect('Buscar')}
                        >
                          Buscar
                        </div>
                        <div
                          style={{ padding: '8px', cursor: 'pointer' }}
                          onClick={() =>
                            this.handlePopoverSelect('Buscar por Número')
                          }
                        >
                          Buscar por número de processo
                        </div>
                      </Popover>
                    }
                  >
                    <div
                      className="icon-button"
                      style={{
                        fontWeight: 'bold',
                        color: '#D0D5DD',
                        fontSize: '12px',
                        padding: 16,
                        cursor: 'pointer',
                      }}
                    >
                      <ArrowDown2 size="12" color="#053AC8" />
                    </div>
                  </Whisper>
                  <div className="separator" />
                  <Whisper
                    ref={(ref) => (this.searchHistoryRef = ref)}
                    placement="bottomStart"
                    trigger="click"
                    speaker={
                      <Popover
                        id={'search-history-popover'}
                        className="popover-auto-complete"
                        style={{
                          width: '57.5%',
                          border: '1px solid #E4E7EC',
                          borderRadius: '4px',
                        }}
                      >
                        <div className="search-result">
                          <OptionList
                            options={collectionHistoryOptions}
                            setQuery={(text) => this.setState({ query: text })}
                          />
                        </div>

                        {collectionHistoryOptions.length > 0 &&
                          otherOptions.length > 0 && (
                            <div
                              style={{
                                padding: '4px',
                                color: '#98A2B3',
                                marginLeft: '4px',
                                fontSize: '10px',
                              }}
                            >
                              As pessoas também buscam por
                            </div>
                          )}

                        <div
                          className="search-result"
                          style={{ color: '#0D91FE' }}
                        >
                          <OptionList
                            options={otherOptions}
                            setQuery={(text) => this.setState({ query: text })}
                          />
                        </div>

                        <hr />
                        <OperatorsHelper
                          addOperator={this.addOperator}
                          top={true}
                        />
                      </Popover>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginRight: '5px',
                      }}
                    >
                      <SearchNormal1
                        size="18"
                        color="#D0D5DD"
                        variant="Outline"
                        style={{ marginRight: 4, marginLeft: 8 }}
                      />
                      <Input
                        ref={this.searchInputRef}
                        className={`input-result ${isSearchByNumber ? 'no-spinner' : ''}`}
                        type={isSearchByNumber ? 'number' : 'text'}
                        value={query}
                        placeholder="Insira o termo de pesquisa aqui"
                        onChange={(value) => this.handleChangeQuery(value)}
                        onKeyDown={this.handleKeyDown}
                        style={{ flex: 1 }}
                      />
                      {query && (
                        <div
                          className="button-close"
                          style={{
                            fontWeight: 'bold',
                            color: '#D0D5DD',
                            fontSize: '12px',
                            padding: 0,
                            marginRight: 21,
                            cursor: 'pointer',
                          }}
                          onClick={this.handleClearQuery}
                        >
                          X
                        </div>
                      )}
                    </div>
                  </Whisper>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchBar);
