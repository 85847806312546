import { create } from 'zustand';
import { requester } from '../../requesters';

type Tag = {
  id: number;
};

type Collection = {
  id: string;
  tags: Tag[];
};

interface CollectionTagsState {
  collections: Collection[];
  clear: () => void;
  fetch: (collectionId: string) => void;
}

export const useCollectionTags = create<CollectionTagsState>((set) => ({
  collections: [],
  clear: () =>
    set(() => ({
      collections: [],
    })),
  fetch: async (collectionId) => {
    if (collectionId == null)
      throw new Error('You must pass collectionId to function fetch');

    const tags = await requester.collections.getTagsByCollection(collectionId);

    set((state) => {
      let uptCollections = state.collections.filter(
        (collection) => collection.id !== collectionId,
      );
      uptCollections.push({ id: collectionId, tags: tags });
      return { collections: uptCollections };
    });
  },
}));
