import { Link } from 'react-router-dom';

const SeeCollectionMessage = ({
  notificationContent,
  collectionId,
  closeToast,
}) => {
  return (
    <div className="flex items-center justify-between">
      <span className="text-base leading-7 text-[#475467] whitespace-pre-line">
        {notificationContent}
      </span>
      <Link
        className="text-base leading-6 text-[#0069F5] cursor-pointer"
        to={`/colecoes/${collectionId}`}
        onClick={closeToast}
      >
        Ver coleção
      </Link>
    </div>
  );
};

export default SeeCollectionMessage;
