import React, { useState } from 'react';
import { Button } from 'rsuite';

export default function SwitchButtons({ updatePlaceholder }) {
  const [clickedButton, setClickedButton] = useState(1);

  const handleChangeButton = (buttonId) => {
    updatePlaceholder(buttonId);
    setClickedButton(buttonId);
  };

  return (
    <div className="button-input">
      <Button
        className={`btn-left ${clickedButton === 1 ? 'clicked' : 'unclicked'}`}
        onClick={() => handleChangeButton(1)}
      >
        <span>Buscar</span>
      </Button>
      <Button
        className={`btn-right ${clickedButton === 2 ? 'clicked' : 'unclicked'}`}
        onClick={() => handleChangeButton(2)}
      >
        <span>Busca por número de processo</span>
      </Button>
    </div>
  );
}
