import { useEffect } from 'react';
import DatePickerFilter from '../Filters/DatePickerFilter';

const DataPickerRender = ({
  filter,
  tmpFilters,
  context,
  filtersProps,
  updateSelectedFilters,
  isDateFilterDisabled,
  dateRange,
}) => {
  const renderFilterType = (filterType) => {
    switch (filterType) {
      case 'date':
        return (
          <DatePickerFilter
            setTmpFilters={filtersProps?.setTmpFilters}
            filters={tmpFilters}
            context={context}
            filter={filter}
            variant="outlined"
            label={filter.public_name}
            updateSelectedFilters={updateSelectedFilters}
            isDateFilterDisabled={isDateFilterDisabled}
            dateRange={dateRange}
          />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    renderFilterType(filter.type);
  }, []);

  return renderFilterType(filter.type) ? (
    <div className="tv-filter_group">{renderFilterType(filter.type)}</div>
  ) : null;
};

export default DataPickerRender;
