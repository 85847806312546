import { useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Nav, SpinningLoader } from '../../../../../ds-components';

import { CloseCircle, SearchNormal } from 'iconsax-react';

import { Creators as ProfilesActions } from '../../../../../store/ducks/profiles';
import collections, {
  Creators as CollectionsActions,
} from '../../../../../store/ducks/collections';
import RemoveCardFromCollectionButton from './RemoveCardFromCollectionButton';
import AddCardToCollectionButton from './AddCardToCollectionButton';
import CreateAndAddToCollection from './CreateAndAddToCollection';
import TuriviusPreloader from '../../../../../ui/loaders/TuriviusPreloader';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/ui/dialog';
import { cn } from '../../../../../lib/utils';
import { useCollections } from '../../../../../store/ducks/theCollections';

const SaveCardToCollectionButton = ({
  cards,
  query,
  isItInACollection,
  theCollections,
  appearance,
  icon,
  className,
  children,
}) => {
  const { loading: loadingTheCollections } = useCollections();
  const [searchString, setSearchString] = useState('');
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const handleChangeNavigation = (index) => {
    setActiveIndex(index);
  };

  const getCardsInCollection = (cards, collection) => {
    const collectionCardIds = collection.cards;
    return cards.filter((card) => collectionCardIds.includes(card.id));
  };

  const checkIfAllCardIdsHaveInACollection = (cards, collection) => {
    const cardIds = cards?.map((card) => card.id);
    const collectionCardIds = collection.cards;
    return cardIds.every((cardId) => collectionCardIds.includes(cardId));
  };

  const theCollectionsFiltered = (collections) => {
    if (searchString === '') return collections;

    let collectionFilters = collections.filter((collection) =>
      collection.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          searchString
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    );

    const mapFromCollections = new Map(
      collectionFilters.map((collection) => [collection.id, collection]),
    );
    const uniqueCollections = [...mapFromCollections.values()];

    return uniqueCollections;
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) =>
        setOpen(isOpen ? isOpen : false, setSearchString(''))
      }
    >
      <DialogTrigger asChild>
        <Button
          appearance={appearance}
          icon={icon}
          size="sm"
          onClick={() => setOpen(true)}
          className={cn('save-collection-tour', className)}
          data-testid={isItInACollection}
        >
          {children}
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-[600px] max-h-[calc(100%-48px)] translate-y-0 top-6">
        <DialogHeader>
          <DialogTitle className="save-collection_header font-semibold">
            Salvar em coleção
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col overflow-y-auto pr-3">
          <span>Escolha uma coleção para salvar, ou crie uma nova.</span>
          <Nav
            navItems={[
              { id: 1, title: `Buscar coleção` },
              {
                id: 2,
                title: 'Criar coleção',
                'data-testid': 'modal-create-collection-tab',
              },
            ]}
            activeTab={activeIndex}
            handleChange={handleChangeNavigation}
          />

          {activeIndex === 1 && (
            <div className="gap-6 flex flex-col">
              <InputGroup inside>
                <InputGroup.Button>
                  <SearchNormal size={12} color="#98A2B3" />
                </InputGroup.Button>
                <Input
                  autoFocus
                  placeholder="Buscar coleção"
                  size="sm"
                  value={searchString}
                  onChange={(event) => setSearchString(event)}
                />
              </InputGroup>
              <div className="flex flex-col min-h-28">
                {loadingTheCollections && (
                  <div className="absolute" style={{ left: '46%', top: '54%' }}>
                    <TuriviusPreloader />
                  </div>
                )}

                {!loadingTheCollections &&
                  theCollectionsFiltered(theCollections).length === 0 && (
                    <span>Você não tem coleções</span>
                  )}
                {!loadingTheCollections &&
                  theCollectionsFiltered(theCollections)?.map(
                    (collection, key) => {
                      const cardsInCollection = getCardsInCollection(
                        cards,
                        collection,
                      );
                      return (
                        <div
                          key={`${collection.name}-${key}`}
                          className="save-collection_content-table-row"
                        >
                          <span>{collection.name}</span>

                          <div className="flex flex-row gap-2">
                            {cardsInCollection.length > 0 && (
                              <RemoveCardFromCollectionButton
                                noconfirm
                                cards={cardsInCollection}
                                collectionId={collection.id}
                                context="Results"
                                appearance={`${cardsInCollection.length === cards.length ? 'danger' : 'minimal'}`}
                                icon={<CloseCircle size={16} color="white" />}
                                loadingIcon={
                                  <SpinningLoader
                                    color={`${cardsInCollection.length === cards.length ? 'white' : 'gray'}`}
                                    size="sm"
                                  />
                                }
                              >
                                <span
                                  className={`${cardsInCollection.length === cards.length ? '' : 'text-red-500'}`}
                                >
                                  Remover
                                </span>
                              </RemoveCardFromCollectionButton>
                            )}

                            {cardsInCollection.length !== cards.length && (
                              <AddCardToCollectionButton
                                cards={cards}
                                collection={collection}
                                context="Results"
                              />
                            )}
                          </div>
                        </div>
                      );
                    },
                  )}
              </div>
            </div>
          )}

          {activeIndex === 2 && (
            <div className="mt-5">
              <CreateAndAddToCollection
                cards={cards}
                context="Results"
                query={query}
                handleChangeNavigation={handleChangeNavigation}
              />
            </div>
          )}
        </div>

        <DialogFooter>
          <Button
            size="sm"
            appearance="primary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Concluído
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  userCollections: state.collections.userCollections,
  requestingUserCollections: state.collections.requestingUserCollections,
  defaultCollectionSave: state.collections.defaultCollectionSave,
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, CollectionsActions, ProfilesActions),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveCardToCollectionButton);
