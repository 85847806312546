import React from 'react';
import { Turivius } from '../../../../../ui';
import { requester } from '../../../../../requesters';
import { withRouter } from 'react-router-dom';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import {
  Input,
  InputGroup,
  Panel,
  toaster,
  Message,
  Grid,
  Row,
  Whisper,
  Popover,
  Col,
  Divider,
  TagPicker,
  Button,
  Tooltip,
} from 'rsuite';
import { SearchNormal1, Setting4 } from 'iconsax-react';
import EntityList from '../EntityList';

import TuriviusColors from '../../../../../vars.module.scss';
import SingleFilter from '../../results/FilterList/Filters/SingleFilter';
import SynonymsFilter from '../../results/FilterList/Filters/SynonymsFilter';
import DataAvailability from '../../../../../components/organisms/ModalAvailability/DataAvailability';
import InteiroTeorFilter from '../../results/FilterList/Filters/InteiroTeorFilter';
import SearchButtonAdvanced from '../SearchButtonAdvanced';
import DefaultOperatorFilter from '../../results/FilterList/Filters/DefaultOperatorFilter';
import TuriviusCheckboxFilter from '../../results/FilterList/Filters/TuriviusCheckboxFilter';
import TuriviusCheckboxGroupFilter from '../../results/FilterList/Filters/TuriviusCheckboxGroupFilter';
import RelatorMultipleAutocompleteFilter from '../../results/FilterList/Filters/RelatorMultipleAutocompleteFilter';
import OperatorsHelperOpen from '../OperatorsHelperOpen';
import DateFilter from '../../results/FilterList/Filters/DateFilter';
import './style.scss';
import { updateCheckllist } from '../../../../../services/checklist';
import AdvancedFilters from '../../results/AdvancedFilters/AdvancedFilters';
import OperatorsHelper from '../OperatorsHelper';
import SearchInput from './SearchInput';
import SwitchButtons from './SwitchButtons';

class SearchBar extends React.Component {
  state = {
    query: '',
    queryError: false,
    inteiroTeorError: false,
    synonymsError: false,
    defaultOperatorError: false,
    advancedSearchOpened: false,
    selected_relator_ids: [],
    error_relator_ids: false,
    requesting: false,
    relators_trigger_search_timeout: null,
    relator_ids: [],
    n_processo: '',
    n_acordao: '',
    error_n_processo: false,
    error_n_acordao: false,
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.verifyAndSearch();
    }
  };

  handleChangeQuery = (s) => {
    this.searchHistoryRef.open();

    this.setState({ query: s });
  };

  addOperator = (operator) => {
    this.updTour();

    const cursorPosition = document.getElementById(
      'home-page-search-input',
    ).selectionStart;
    let { query } = this.state;
    if (operator === '$') {
      query = `${query.substring(
        0,
        cursorPosition,
      )}${operator}${query.substring(cursorPosition, query.length)}`.replace(
        '  ',
        ' ',
      );
    } else {
      query = `${query.substring(
        0,
        cursorPosition,
      )} ${operator} ${query.substring(cursorPosition, query.length)}`.replace(
        '  ',
        ' ',
      );
    }

    this.setState({ query });
    document.getElementById('home-page-search-input').focus();
    setTimeout(() => {
      document
        .getElementById('home-page-search-input')
        .setSelectionRange(
          cursorPosition + operator.length + 1,
          cursorPosition + operator.length + 1,
        );
    }, 200);
  };

  verifyAndSearch = () => {
    // this.props.verifyTmpFiltersEntitiesPermission(this.props.permissions[this.state.service])
    let filterError = false;
    let queryError = false;
    let inteiroTeorError = false;
    let synonymsError = false;
    let defaultOperatorError = false;

    this.setState({ filterError, queryError, inteiroTeorError });

    if (this.state.query.toLowerCase().includes(' adj ')) {
      queryError = true;
      toaster.push(
        <Message
          position={'bottom'}
          header="O operador ADJ deve vir acompanhado de um número"
          duration={0}
          type="warning"
          showIcon
          closable
        >
          <div style={{ maxWidth: 320 }}>
            <Grid fluid>
              <Row>
                <Col xs={24}>
                  <p>
                    <b>Exemplo</b>
                    <br />
                    <i>
                      multa <b>ADJ2</b> ofício
                    </i>{' '}
                    irá retornar os resultados em que{' '}
                    <u> ofício será no máximo a segunda palavra após multa</u>.
                  </p>
                </Col>
                <Col xs={24}>
                  <blockquote>
                    [...] não pode ser exigida cumulativamente com a{' '}
                    <mark>multa</mark> de <mark>ofício</mark>, aplicável [...]
                  </blockquote>
                </Col>
              </Row>
            </Grid>
          </div>
        </Message>,
      );
    }

    if (
      this.props.tmpFilters &&
      this.props.tmpFilters.entities &&
      this.props.tmpFilters.entities.length === 0
    ) {
      filterError = true;
      toaster.push(
        <Message
          header="Atenção"
          duration={4000}
          type="warning"
          showIcon
          closable
        >
          Selecione ao menos um órgão
        </Message>,
      );
    }

    if (this.props.inteiroTeorFilter?.length === 0) {
      inteiroTeorError = true;
      toaster.push(
        <Message
          header="Atenção"
          duration={4000}
          type="warning"
          showIcon
          closable
        >
          Selecione ao menos um como buscar (ementa e/ou inteiro teor)
        </Message>,
      );
    }

    this.setState({
      queryError,
      filterError,
      inteiroTeorError,
      synonymsError,
      defaultOperatorError,
    });

    if (
      !filterError &&
      !queryError &&
      !inteiroTeorError &&
      !synonymsError &&
      !defaultOperatorError
    ) {
      this.props.saveFilters(
        this.props.history,
        this.props.match,
        encodeURIComponent(this.state.query),
      );
    }
    postHubSpotEvent('app_pesquisa', { hs_page_url: this.state.query });
  };

  setErrorToFalse = () => {
    this.setState({ filterError: false });
  };

  setInteiroTeorErrorToFalse = () => {
    this.setState({ inteiroTeorError: false });
  };

  setSynonymsErrorToFalse = (value) => {
    this.setState({ synonymsError: value });
  };

  setDefaultOperatorErrorToFalse = () => {
    this.setState({ defaultOperatorError: false });
  };

  handleChangeSelectedRelators = (value) => {
    this.setState({
      relator_ids: `[${[...value].join(',')}]`,
      selected_relator_ids: value,
    });
  };

  handleChangeField = (s, e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setError = (errorName) => {
    this.setState({
      error_n_processo: false,
      error_n_acordao: false,
      error_relator_ids: false,
    });
    this.setState({ [errorName]: true });
  };

  selectQueryFromHistory = (querySearch) => {
    let filters = {
      date_range: querySearch.filters?.date_range,
      entity: this.props.tmpFilters.entities,
      filter_by_favorability: querySearch.filters?.filter_by_favorability,
    };
    // this.props.justSaveFilters(JSON.stringify(filters));
    this.setState({ query: querySearch.query });
    // this.searchHistoryRef.close();
    document.getElementById('home-page-search-input').focus();
  };

  updTour = async () => {
    const check = JSON.parse(localStorage.getItem('checklist'));
    if (check.trail.search) {
      return;
    } else {
      await updateCheckllist(check.id, 'search');
    }
  };

  componentDidMount() {
    this.props.clearFilters();
    this.setState({
      placeholder: 1,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      showAdvancedFilters: false,
    };
  }

  handleDrawer = () => {
    this.setState((prevState) => ({
      showAdvancedFilters: !prevState.showAdvancedFilters,
    }));
  };

  handleButtonClick1 = () => {
    this.setState({
      placeholder: 'Quais termos deseja buscar ?',
      clickedButton: 1,
    });
  };

  handleButtonClick2 = () => {
    this.setState({
      placeholder: 'Digite o número do processo ou do acórdão',
      clickedButton: 2,
    });
  };

  updatePlaceholder = (newPlaceholder) => {
    this.setState({ placeholder: newPlaceholder });
  };

  render() {
    const { showAdvancedFilters } = this.state;
    const { placeholder, clickedButton } = this.state;
    const {
      permissions,
      entitiesPermissions,
      originalFilters,
      searchHistory,
      loadindSearchHistory,
      getRelatorsNames,
      relatorNames,
      segment,
      oldFilters,
    } = this.props;

    const {
      query,
      queryError,
      filterError,
      advancedSearchOpened,
      //relator_names,
      selected_relator_ids,
      error_relator_ids,
      relator_ids,
      requesting,
      relators_trigger_search_timeout,
      n_processo,
      n_acordao,
      error_n_processo,
      error_n_acordao,

      entitiesRequest,
      loadingEntities,
      service,
    } = this.state;
    const filters = Object.values(originalFilters);
    return (
      <div style={{ margin: '50px 0px' }}>
        <Grid fluid className="grid-search-options">
          <SwitchButtons updatePlaceholder={this.updatePlaceholder} />
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              xxlHidden
              xlHidden
              lgHidden
              mdHidden
            >
              <div style={{ marginBottom: 16 }}></div>
            </Col>
            <div className="input-icon-search">
              <SearchNormal1 size="24" />
            </div>
            <Col lg={24} md={24} sm={24} xs={24}>
              <InputGroup
                inside
                id="search-card-query-col"
                size="lg"
                className="search-bar"
                style={{ width: '100%' }}
              >
                <SearchInput
                  queryError={this.queryError}
                  setQuery={this.props.allProps.setQuery}
                  query={this.props.allProps.query}
                  placeholder={placeholder}
                />
                <div className="input-search-section">
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        Aplique filtros e encontre melhores resultados
                      </Tooltip>
                    }
                  >
                    <InputGroup.Button
                      id="btn-filters-advanced"
                      onClick={this.handleDrawer}
                    >
                      <Setting4 size={14} />
                      Filtros avançados
                    </InputGroup.Button>
                  </Whisper>

                  <AdvancedFilters
                    open={showAdvancedFilters}
                    onClose={() =>
                      this.setState({ showAdvancedFilters: false })
                    }
                    AllProps={this.props.allProps}
                    tmpFilters={this.props.allProps.tmpFilters}
                    filtersProps={this.props.allProps.filtersRedux}
                    entities={this.props.entitiesMonitoring}
                    query={this.props.allProps.query}
                  />

                  <InputGroup.Button
                    id="btn-search"
                    onClick={() => {
                      const searchTerm = this.props.filtersProps.query; // supondo que o termo de busca esteja em query
                      if (searchTerm.length >= 3) {
                        this.props.filtersProps.saveFilters(
                          this.props.filtersProps.history,
                          this.props.filtersProps.match,
                          encodeURIComponent(searchTerm),
                        );
                      } else {
                        toaster.push(
                          <Message
                            duration={4000}
                            type="warning"
                            showIcon
                            closable
                          >
                            Sua busca deve ter pelo menos três caracteres
                          </Message>,
                          { placement: 'topEnd' },
                        );
                      }
                    }}
                  >
                    Buscar
                  </InputGroup.Button>
                </div>
              </InputGroup>
            </Col>
          </Row>

          {/* </div> */}
          <Row style={{ marginTop: 10 }}>
            <Col lg={12} md={12} sm={24} xs={24} xsHidden smHidden>
              <div className="operators-container">
                {/* <Turivius.TuriviusSearchOperators addOperator={this.addOperator} /> */}
              </div>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={24}
                style={{ padding: 0, marginBottom: 5 }}
              >
                {/* <EntityModal
                  permissions={entitiesPermissions}
                  segment={segment}
                  filters={this.props.filters}
                  context={"Home"}
                  className="search-entity"
                  error={filterError}
                  onClick={this.setErrorToFalse}
                  setTmpFilters={this.props.setTmpFilters}
                  entities={this.props.entities}
                  entities_counter={this.props.entities_counter}
                /> */}
              </Col>

              {/* </div> */}
            </Col>
          </Row>
        </Grid>
        <Panel
          className={`advanced-search ${
            advancedSearchOpened ? 'hide-header' : ''
          }`}
          expanded={advancedSearchOpened}
          collapsible
        >
          <div className="panel-container">
            <Grid fluid>
              <Row className="line-labels">
                <Col sm={24} className="search-operators">
                  <Col className="column-item-option" xs={24} sm={24} md={8}>
                    <DefaultOperatorFilter
                      filtersProps={this.props.filtersProps}
                      context={'Home'}
                    />
                  </Col>
                  <Col className="column-item-option" xs={24} sm={24} md={8}>
                    <SynonymsFilter
                      filtersProps={this.props.filtersProps}
                      context={'Home'}
                    />
                  </Col>
                  <Col className="column-item-option" xs={24} sm={24} md={8}>
                    <InteiroTeorFilter
                      filtersProps={this.props.filtersProps}
                      context={'Home'}
                    />
                  </Col>
                </Col>

                <Col sm={24} className="search-filters">
                  {filters && filters.length > 0
                    ? filters
                        ?.filter(
                          (f) =>
                            f.field_name !== 'entity' &&
                            f.field_name !== 'filter_by_relator',
                        )
                        ?.map((filter) => {
                          let filterComponent = null;
                          switch (filter.type) {
                            case 'single':
                              filterComponent = (
                                <SingleFilter
                                  filtersProps={this.props.filtersProps}
                                  context={'Home'}
                                  filter={filter}
                                  label={<b>{filter.public_name}</b>}
                                  labelKey="name"
                                  placeholder={filter.public_name}
                                />
                              );
                              break;
                            case 'multiple_select':
                              filterComponent = (
                                <TuriviusCheckboxFilter
                                  filters={filters}
                                  filtersProps={this.props.filtersProps}
                                  context={'Home'}
                                  filter={filter}
                                  label={<b>{filter.public_name}</b>}
                                  filterReference={filter.field_name}
                                  labelKey="name"
                                  placeholder={filter.public_name}
                                  cols={1}
                                />
                              );
                              break;
                            case 'date':
                              filterComponent = (
                                <DateFilter
                                  setTmpFilters={
                                    this.props.filtersProps.setTmpFilters
                                  }
                                  filters={this.props.filtersProps.tmpFilters}
                                  context={'Home'}
                                  filter={filter}
                                  label={<b>{filter.public_name}</b>}
                                />
                              );
                              break;
                            case 'multiple_autocomplete':
                              filterComponent = (
                                <RelatorMultipleAutocompleteFilter
                                  filtersProps={this.props.filtersProps}
                                  context={'Home'}
                                  filter={filter}
                                  label={<b>{filter.public_name}</b>}
                                  microservice="cards"
                                  action="relator_by_partial_name"
                                  autocompleteFilter="entities"
                                  labelKey="key"
                                  placeholder={filter.public_name}
                                />
                              );
                              break;
                            case 'multiple':
                              filterComponent = (
                                <TuriviusCheckboxGroupFilter
                                  filtersProps={this.props.filtersProps}
                                  context={'Home'}
                                  filter={filter}
                                  label={<b>{filter.public_name}</b>}
                                  filterReference="entities"
                                  action={requester.cards.getEntities}
                                  segment={this.props.segment}
                                  labelKey="initials"
                                  placeholder={filter.public_name}
                                />
                              );
                              break;
                            default:
                              filterComponent = null;
                              break;
                          }
                          return (
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              key={filter.field_name}
                              className="column-item-label"
                            >
                              <div
                                id={filter.field_name}
                                className="filter-section-content"
                              >
                                <div
                                  className="input-field-header"
                                  style={{ marginBottom: 12 }}
                                >
                                  {filterComponent}
                                  <span
                                    className={`rs-custom-input-helper-text`}
                                  >
                                    {filter.field_name ===
                                    'filter_by_favorability' ? (
                                      <DataAvailability
                                        context="jurimetrics"
                                        trigger={
                                          <div>
                                            <b style={{ color: '#333' }}>
                                              Atenção:
                                            </b>
                                            <a
                                              style={{ cursor: 'pointer' }}
                                              role="button"
                                            >
                                              {' '}
                                              clique aqui e saiba quais os
                                              órgãos com jurimetria disponível.
                                            </a>
                                          </div>
                                        }
                                      />
                                    ) : filter.help ? (
                                      filter.help.replace(/&#013/gi, '')
                                    ) : (
                                      <>&nbsp;</>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                    : 'Nenhum filtro a ser aplicado'}
                </Col>
                <Turivius.TuriviusButton
                  backgroundColor={TuriviusColors.turivius}
                  color="#FFFFFF"
                  onClick={this.verifyAndSearch}
                  fullWidth
                >
                  Buscar
                </Turivius.TuriviusButton>
              </Row>
              <Divider>
                {' '}
                <b>OU</b>{' '}
              </Divider>
              <Row className="line-labels search-without-query">
                <Col
                  className="column-item-label-single"
                  xs={24}
                  sm={24}
                  md={12}
                >
                  <span className="title-label-single">Número do acórdão:</span>
                  <div className="container-item-label-single">
                    <Input
                      className={`item-label-single ${
                        error_n_acordao ? 'rs-custom-input-error' : ''
                      }`}
                      name="n_acordao"
                      value={n_acordao}
                      onChange={this.handleChangeField}
                    />
                    <SearchButtonAdvanced
                      title="Buscar Acórdão"
                      errorAction={this.setError}
                      propName="n_acordao"
                      value={n_acordao}
                      history={history}
                    />
                  </div>
                  <span
                    className={`rs-custom-input-helper-text ${
                      error_n_acordao ? 'error' : 'hidden'
                    }`}
                  >
                    Digite o número do acórdão
                  </span>
                </Col>
                <Col
                  className="column-item-label-single"
                  xs={24}
                  sm={24}
                  md={12}
                >
                  <span className="title-label-single">
                    Número do processo:
                  </span>
                  <div className="container-item-label-single">
                    <Input
                      className={`item-label-single ${
                        error_n_processo ? 'rs-custom-input-error' : ''
                      }`}
                      name="n_processo"
                      value={n_processo}
                      onChange={this.handleChangeField}
                    />
                    <SearchButtonAdvanced
                      title="Buscar Processo"
                      errorAction={this.setError}
                      propName="n_processo"
                      value={n_processo}
                      history={this.props.history}
                    />
                  </div>
                  <span
                    className={`rs-custom-input-helper-text ${
                      error_n_processo ? 'error' : 'hidden'
                    }`}
                  >
                    Digite o número do processo
                  </span>
                </Col>

                <Col
                  className="column-item-label-single"
                  xs={24}
                  sm={24}
                  md={12}
                >
                  <span className="title-label-single">Relatores:</span>
                  <div className="container-item-label-single">
                    <TagPicker
                      className={`item-label-single ${
                        error_relator_ids ? 'rs-custom-input-error' : ''
                      }`}
                      placeholder={
                        'Selecione relatores (digite ao menos três caracteres)'
                      }
                      data={relatorNames}
                      cacheData={selected_relator_ids?.map((sri) => ({
                        key: sri,
                      }))}
                      value={selected_relator_ids}
                      labelKey="key"
                      valueKey="key"
                      cleanable={false}
                      placement={'topStart'}
                      onChange={(value) =>
                        this.handleChangeSelectedRelators(value)
                      }
                      onSearch={(newInputValue) => {
                        if (relators_trigger_search_timeout) {
                          clearTimeout(relators_trigger_search_timeout);
                        }
                        this.setState({
                          relators_trigger_search_timeout: setTimeout(() => {
                            if (newInputValue.length >= 3) {
                              getRelatorsNames(newInputValue);
                            }
                          }, 500),
                        });
                      }}
                      renderMenu={(menu) => {
                        if (requesting) {
                          return (
                            <p
                              style={{
                                padding: 4,
                                color: '#999',
                                textAlign: 'center',
                              }}
                            >
                              <Turivius.SimplePreloader /> Carregando...
                            </p>
                          );
                        }
                        return menu;
                      }}
                    />
                    <SearchButtonAdvanced
                      title="Buscar Relator(es)"
                      errorAction={this.setError}
                      propName="relator_ids"
                      value={relator_ids}
                      history={history}
                    />
                  </div>
                  <span
                    className={`rs-custom-input-helper-text ${
                      error_relator_ids ? 'error' : 'hidden'
                    }`}
                  >
                    Digite ao menos três caracteres para exibir os relatores
                  </span>
                </Col>
              </Row>
            </Grid>
          </div>
        </Panel>
      </div>
    );
  }
}

export default withRouter(SearchBar);
