const worksheet = {
  font: {
    name: 'Arial',
    sz: 11,
  },
  alignment: {
    vertical: 'center',
  },
};

const metadata = {
  font: {
    ...worksheet.font,
  },
  alignment: {
    ...worksheet.alignment,
  },
};

const sourceMetadata = {
  font: {
    ...metadata.font,
    color: {
      rgb: '0d91fe',
    },
  },
  alignment: {
    ...metadata.alignment,
  },
};

const table = {
  font: {
    ...worksheet.font,
  },
  alignment: {
    ...worksheet.alignment,
  },
  border: {
    top: { style: 'thin', color: { rgb: '999999' } },
    bottom: { style: 'thin', color: { rgb: '999999' } },
    left: { style: 'thin', color: { rgb: '999999' } },
    right: { style: 'thin', color: { rgb: '999999' } },
  },
};

const tableHeader = {
  fill: {
    fgColor: { rgb: '053ac8' },
  },
  font: {
    ...table.font,
    bold: true,
    color: { rgb: 'FFFFFF' },
  },
  alignment: {
    ...table.alignment,
    horizontal: 'center',
  },
  border: {
    ...table.border,
  },
};

const tableBody = {
  font: {
    ...table.font,
    color: { rgb: '667085' },
  },
  alignment: {
    ...table.alignment,
    horizontal: 'center',
  },
  border: {
    ...table.border,
  },
};

const ementaColBody = {
  font: {
    ...tableBody.font,
  },
  alignment: {
    ...tableBody.alignment,
    vertical: 'top',
    horizontal: 'left',
  },
  border: {
    ...tableBody.border,
  },
};

const commentsColBody = {
  font: {
    ...tableBody.font,
  },
  alignment: {
    ...tableBody.alignment,
    vertical: 'top',
    horizontal: 'left',
  },
  border: {
    ...tableBody.border,
  },
};

const inteiroTeorColBody = {
  font: {
    ...tableBody.font,
    color: {
      rgb: '0d91fe',
    },
    underline: true,
  },
  alignment: {
    ...tableBody.alignment,
  },
  border: {
    ...tableBody.border,
  },
};

const cardsWorksheetStyles = {
  worksheet: worksheet,
  metadata: metadata,
  sourceMetadata: sourceMetadata,
  table: table,
  tableHeader: tableHeader,
  tableBody: tableBody,
  ementaColBody: ementaColBody,
  commentsColBody: commentsColBody,
  inteiroTeorColBody: inteiroTeorColBody,
};

export default cardsWorksheetStyles;
