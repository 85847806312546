import TuriviusSidenav from '../TuriviusSidenav';
import { useEffect, useState } from 'react';
import { Container, Content } from 'rsuite';
import '../../../ds-components/styles/index.jsx.css';
import 'rsuite/dist/rsuite.min.css';
import './style.scss';
import { useLocation } from 'react-router-dom';

const TuriviusBasePage = ({ children }) => {
  const location = useLocation();
  const [isPesquisaResultados, setIsPesquisaResultados] = useState(false);

  useEffect(() => {
    if (
      location.pathname === '/pesquisa/resultados' &&
      location.search.includes('query')
    ) {
      setIsPesquisaResultados(true);
    } else {
      setIsPesquisaResultados(false);
    }
  }, [location]);

  return (
    <Container style={{ minHeight: '100vh' }}>
      <TuriviusSidenav />
      {isPesquisaResultados ? (
        <div style={{ width: '100%' }}>{children}</div>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

export default TuriviusBasePage;
