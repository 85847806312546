import React from 'react';

export type AvatarProps = {
  type: 'circle';
  size: 'xs' | 'sm' | 'md' | 'lg';
  color?:
    | 'primary'
    | 'gray'
    | 'success'
    | 'warning'
    | 'danger'
    | 'yellow'
    | 'orange'
    | 'violet'
    | 'azure';
  image?: string;
  placeholder?: boolean;
  initial?: string;
};

export const Avatar: React.FC<AvatarProps> = ({
  type,
  size,
  color,
  image,
  initial,
  placeholder = image ? false : initial ? false : true,
}) => {
  return (
    <>
      {image ? (
        <div
          className={`tv-avatar tv-avatar-${type} tv-avatar-${type}_${size} tv-avatar-${color} font-semibold`}
        >
          <img src={image} />
        </div>
      ) : null}
      {initial ? (
        <div
          className={`tv-avatar tv-avatar-${type} tv-avatar-${type}_${size} tv-avatar-${color} font-semibold`}
        >
          <em>{initial}</em>
        </div>
      ) : placeholder ? (
        <div
          className={`tv-avatar tv-avatar-${type} tv-avatar-${type}_${size} tv-avatar-${color} font-semibold`}
        >
          <em>{initial}</em>
        </div>
      ) : null}
    </>
  );
};

export default Avatar;
