import { useState, useEffect, useRef } from 'react';
import { requester, posthog } from '../../../../requesters';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Creators as SearchActions } from '../../../../store/ducks/searchTributario';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import BrowserDetection from 'react-browser-detection';

import FlagSystem from '../../../../components/FlagSystem';
import SearchBar from '../../components/search/SearchBar';
import './HomePage.scss';

import { toaster, Modal, FlexboxGrid } from 'rsuite';
import { Button, SpinningLoader } from '../../../../ds-components';
import IndependentBottomSheetNotification from '../../../account/notifications/components/BottomSheetNotification/IndependentBottomSheetNotification';
import { BigLaunchMode } from '../../components/results/Cards/Result/big-launch-mode';
import RecentSearchBox from '../../components/search/CardsHome/RecentSearchBox';
import RecentArticleBox from '../../components/search/CardsHome/RecentSearchBox';
import RecentArticlesBox from '../../components/search/CardsHome/RecentArticlesBox';

const HomePage = (props) => {
  const {
    saveFilters,
    tmpFilters,
    inteiroTeor,
    originalFilters,
    justSaveFilters,
    filters,
    setTmpFilters,
    entities_counter,
    clearFilters,
  } = props;

  const [entitiesMonitoring, setEntitiesMonitoring] = useState([]);

  const getEntitiesToMonitoring = () => {
    requester.cards
      .getEntities()
      .then((res) => {
        const entitiesObject = res.reduce((acc, entity, index) => {
          acc[index] = entity;
          return acc;
        }, {});
        setEntitiesMonitoring(entitiesObject);
      })
      .catch((err) => {
        setEntitiesMonitoring({});
      });
  };

  const triggerSuccessChecklist = useRef();
  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  const permissions = JSON.parse(localStorage.getItem('permissions'))[
    'tributario'
  ]
    ? JSON.parse(localStorage.getItem('permissions'))['tributario']
    : null;
  const [entitiesPermissions, setEntitiesPermissions] = useState({});
  const [requestingSearchHistory, setRequestingSearchHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState(null);
  const [filter, setFilter] = useState(null);
  const [requesting, setRequesting] = useState(false);
  const [relatorNames, setRelatorNames] = useState([]);
  const [entitiesOpen, setEntitiesOpen] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [seeTerms, setSeeTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.search();
  }, []);

  const browserHandler = {
    ie: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Internet Explorer como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
    edge: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Microsoft Edge como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
    safari: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Safari como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
    opera: () => (
      <IndependentBottomSheetNotification
        style={{ backgroundColor: '#ffcc00', color: '#343434' }}
      >
        Detectamos que você está utilizando o Opera como navegador!
        <br />
        Recomendamos que utilize o Google Chrome ou Mozilla Firefox para uma
        melhor experiência com a Turivius
      </IndependentBottomSheetNotification>
    ),
  };

  const entities = () => {
    const uhum = {};
    let permissions_trib = JSON.parse(localStorage.getItem('permissions'))[
      'tributario'
    ].entidades
      ? JSON.parse(localStorage.getItem('permissions'))['tributario'].entidades
      : null;
    let permissions_trab = JSON.parse(localStorage.getItem('permissions'))[
      'trabalhista'
    ].entidades
      ? JSON.parse(localStorage.getItem('permissions'))['trabalhista'].entidades
      : null;
    if (permissions_trib) {
      Object.entries(permissions_trib).forEach(([key, value]) => {
        uhum[key] = value;
      });
    }
    if (permissions_trab) {
      Object.entries(permissions_trab).forEach(([key, value]) => {
        uhum[key] = value;
      });
    }
    setEntitiesPermissions(uhum);
    return uhum;
  };
  const getSearchHistory = () => {
    setRequestingSearchHistory(true);
    requester.cards
      .searchHistory()
      .then((response) => {
        let searchHistoryRes = response
          .filter((r) => r.query !== '')
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t.query === value.query && t.name === value.name,
              ),
          )
          ?.map((r) => {
            Object.keys(r.filters).forEach((filterKey) => {
              if (filterKey === 'entity') {
                r.filters[filterKey] = r.filters[filterKey]?.map((e) =>
                  parseInt(e),
                );
              }
            });
            return r;
          });
        setSearchHistory(searchHistoryRes);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setRequestingSearchHistory(false);
      });
  };
  const getFilters = async () => {
    if (
      props.originalFilters &&
      Object.keys(props.originalFilters).length === 0
    ) {
      await requester.cards
        .getAllFilters()
        .then((response) => {
          const filters = response;
          let defaultFilters = {};
          let entityFilter = {};
          filters.forEach((filter) => {
            if (filter.field_name === 'entity') {
              defaultFilters.entities = filter.default;
              entityFilter = { ...filter };
            } else {
              defaultFilters[filter.field_name] = filter.default;
            }
          });

          props.setOriginalFilters(filters);
          props.setDefaultFilters(defaultFilters);
          props.loadFilters();
          props.restoreOptionalFilters();
          setFilter(entityFilter);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setRequesting(false);
        });
    }
  };
  const getRelatorsNames = (partial_name) => {
    if (!requesting) {
      setRequesting(true);
      requester.cards
        .getRelatorsByName(partial_name)
        .then((response) => {
          setTimeout(() => {
            setRelatorNames([...response]);
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setRequesting(false));
    }
  };
  const agree = () => {
    setLoading(true);
    const user = localStorage.getItem('user');
    requester.profiles
      .createUserAgreement(user, true, true)
      .then((response) => {
        return requester.profiles.getById(user);
      })
      .then((profile) => {
        localStorage.setItem('profile', JSON.stringify(profile));
        props.setProfile(profile);
      })
      .finally(() => {
        setLoading(false);
        setSeeTerms(false);
        toaster.clear();
      });
  };
  const validTerms = () => {
    let profile = JSON.parse(localStorage.getItem('profile'));
    if (profile?.agreements?.length === 0) {
      setSeeTerms(true);
    }
  };

  useEffect(() => {
    document.title = 'Busca Jurisprudencial';
    validTerms();
    entities();
    getSearchHistory();
    getFilters();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    posthog.capture('$pageview');
    getEntitiesToMonitoring();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('tour') && localStorage.getItem('profile')) {
      const { tour } = JSON.parse(localStorage.getItem('tour'));
      const profile = JSON.parse(localStorage.getItem('profile'));
      tour.search && profile.onboarding_done
        ? setRunTour(false)
        : setRunTour(true);
      return;
    }
  }, [localStorage.getItem('tour')]);

  const handleClickEntities = () => {
    setEntitiesOpen(true);
  };

  return (
    <div className="container-page">
      <BrowserDetection>{browserHandler}</BrowserDetection>
      <BigLaunchMode />
      <Modal open={seeTerms}>
        <Modal.Body>
          <h5>Termos de Uso e Política de Privacidade</h5>
          <br />
          <p>
            Para continuar utilizando a plataforma da Turivius é necessário que
            você esteja ciente e de acordo com os nossos &nbsp;
            <a href="https://turivius.com/termos-de-uso" target="__blank">
              Termos de Uso
            </a>
            &nbsp; e{' '}
            <a
              href="https://turivius.com/politica-de-privacidade"
              target="__blank"
            >
              Política de privacidade
            </a>
            .
          </p>
        </Modal.Body>

        <Modal.Footer>
          {loading ? (
            <Button size="sm" appearance="secondary" disabled={true}>
              <SpinningLoader size="sm" color="gray" />
            </Button>
          ) : (
            <Button size="sm" appearance="secondary" onClick={() => agree()}>
              Li e aceito os Termos de Uso e Política de Privacidade
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={24}>
          <FlagSystem
            current="jurisprudence"
            openSuccessChecklist={openSuccessChecklist}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          colspan={20}
          style={{ padding: '0 10px', marginTop: '50px' }}
        >
          <div className="title-page">
            <div className="title-page-content">
              <h3>Faça sua pesquisa, otimize seu tempo.</h3>

              <p>
                Busque por tema, número de processo ou nome de cliente em um
                único lugar
              </p>
              <SearchBar
                firstSearch={true}
                segment={'tributario'}
                permissions={permissions['tributario']}
                entitiesPermissions={entitiesPermissions}
                searchHistory={searchHistory}
                loadindSearchHistory={requestingSearchHistory}
                getRelatorsNames={getRelatorsNames}
                relatorNames={relatorNames}
                originalFilters={originalFilters}
                tmpFilters={tmpFilters}
                inteiroTeorFilter={inteiroTeor}
                justSaveFilters={justSaveFilters}
                saveFilters={saveFilters}
                filters={filters}
                oldFilters={filters}
                setTmpFilters={setTmpFilters}
                entities_counter={entities_counter}
                clearFilters={clearFilters}
                filtersProps={props}
                handleClickEntities={handleClickEntities}
                allProps={props}
                entitiesMonitoring={entitiesMonitoring}
              />
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <div className="home-cards_container">
        <div className="home-cards " id="hot-topics">
          <div className="home-box-content">
            <RecentSearchBox />
            <RecentArticlesBox />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clearFilters: state.searchTributario.clearFilters,
  originalFilters: state.searchTributario.originalFilters,
  tmpFilters: state.searchTributario.tmpFilters,
  inteiroTeor: state.searchTributario.inteiroTeor,
  entities: state.searchTributario.entities,
  permissions: state.profiles.permissions,
  filters: state.searchTributario.filters,
  entities_counter: state.searchTributario.entities_counter,
  query: state.searchTributario.query,
  firstSearch: state.searchTributario.firstSearch,
  filterStr: state.searchTributario.filterStr,
  synonyms: state.searchTributario.synonyms,
  defaultOperator: state.searchTributario.defaultOperator,
  expanded: state.searchTributario.expanded,
  validEntities: state.jurimetrics.validEntities,
  externalFilters: state.searchTributario.externalFilters,
  profile: state.profiles.profile,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, ProfilesActions, SearchActions),
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HomePage));
