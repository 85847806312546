import React from 'react';
import {
  Message,
  toaster,
  Grid,
  Row,
  Col,
  Modal,
  Whisper,
  Tooltip,
} from 'rsuite';

import { Chart } from 'iconsax-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as JurimetricsActions } from '../../../../../../store/ducks/jurimetrics';
import { requester, posthog } from '../../../../../../requesters';
import { Turivius } from '../../../../../../ui';
import './style.scss';
import { Button } from '../../../../../../ds-components';
import CardActions from './CardActions';

class MoreInfoResultCard extends React.Component {
  state = {
    data: {
      id: 0,
    },
    on_focus: null,
    openModal: false,
  };

  getJurimetrics = () => {
    let data = {
      id: this.props.card.id,
    };
    this.setState({ data: data });
    Object.keys(data).forEach((key) =>
      data[key] === undefined || data[key] === null ? delete data[key] : '',
    );
    if (!this.props.jurimetrics[JSON.stringify(data)]) {
      requester.jurimetrics
        .getCardPlots(this.props.card?.relator.name)
        .then((response) => {
          this.props.setJurimetric(data.id, response);
        })
        .catch((err) => {
          console.log(err);
          toaster.push(
            <Message
              header="Ocorreu um erro"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Houve um problema de conexão, tente novamente.
            </Message>,
          );
        });
    }
  };

  closeModal = () => {
    this.setState({ on_focus: null });
    posthog.capture(this.props.context, {
      action: 'close_jurimetrics',
      id: this.props.card.id,
    });
  };

  openModal = () => {
    posthog.capture(this.props.context, {
      action: 'open_jurimetrics',
      id: this.props.card.id,
    });
  };

  handleOpen = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  render() {
    const { card, collectionId, jurimetrics, validEntities } = this.props;

    const { data, openModal } = this.state;
    return (
      <>
        {true ? (
          validEntities.includes(Number(card.entity.id)) ? (
            true ? (
              <span>
                <Button
                  id={`more-info-button-${card.id}`}
                  className="button-relator"
                  appearance="secondary"
                  onClick={this.handleOpen}
                  size="sm"
                  icon={<Chart color="#475467" size={12} />}
                >
                  Estatística do relator
                </Button>
              </span>
            ) : (
              <Whisper
                trigger={'hover'}
                placement={'top'}
                controlId={`tooltip-unavailable-jurimetrics-${card.id}`}
                speaker={
                  <Tooltip>
                    Seu plano não permite acesso a estatísticas deste órgão
                  </Tooltip>
                }
              >
                <span>
                  <Button
                    id={`more-info-button-${card.id}`}
                    appearance="secondary"
                    className="button-relator"
                    size="sm"
                    disabled
                    icon={<Chart color="#475467" size={12} />}
                  >
                    Estatística do relator
                  </Button>
                </span>
              </Whisper>
            )
          ) : (
            <Whisper
              trigger={'hover'}
              placement={'top'}
              controlId={`tooltip-disabled-jurimetrics-${card.id}`}
              speaker={
                <Tooltip>
                  A estatística desse órgão ainda não está disponível
                </Tooltip>
              }
            >
              <span>
                <Button
                  id={`more-info-button-${card.id}`}
                  appearance="secondary"
                  size="sm"
                  disabled
                  icon={<Chart color="#475467" size={12} />}
                  style={{ zIndex: '20 !important' }}
                >
                  Estatística do relator
                </Button>
              </span>
            </Whisper>
          )
        ) : (
          <Turivius.SimplePreloader />
        )}
        <Modal
          open={openModal}
          onEntered={this.getJurimetrics}
          onClose={this.handleClose}
          size="full"
          id="modal-more-information"
        >
          <Modal.Header>
            <h5>
              <div className="title">{card.nome}</div>
            </h5>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col xs={24} sm={24}>
                  <Row>
                    <Col xs={24} sm={24} className="modal-jurimetrics">
                      {data && jurimetrics[data.id] ? (
                        jurimetrics[data.id].length > 0 ? (
                          <Grid fluid>
                            <Row>
                              {jurimetrics[data.id]?.map((jurimetric, i) => {
                                switch (jurimetric.type) {
                                  case 'line':
                                    return (
                                      <Col
                                        style={{ marginTop: 10 }}
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={12}
                                      >
                                        <h5 style={{ textAlign: 'center' }}>
                                          {jurimetric.title}
                                        </h5>
                                        <p style={{ textAlign: 'center' }}>
                                          {jurimetric.subtitle}
                                        </p>
                                        <Turivius.TuriviusLineChart
                                          style={{ width: '100%', height: 300 }}
                                          key={`relator-${i}`}
                                          id={`relator-${i}`}
                                          data={jurimetric.data.data}
                                          getColors={(value) => {
                                            return jurimetric.data.config
                                              .colors[value.id];
                                          }}
                                        />
                                      </Col>
                                    );
                                  case 'bar':
                                    return (
                                      <Col
                                        style={{ marginTop: 10 }}
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={12}
                                      >
                                        <h5 style={{ textAlign: 'center' }}>
                                          {jurimetric.title}
                                        </h5>
                                        <p style={{ textAlign: 'center' }}>
                                          {jurimetric.subtitle}
                                        </p>
                                        <Turivius.TuriviusBarChart
                                          style={{ width: '100%', height: 300 }}
                                          key={`relator-${i}`}
                                          id={`relator-${i}`}
                                          margin={{
                                            top: 20,
                                            right: 30,
                                            bottom: 50,
                                            left: 50,
                                          }}
                                          axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 8,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 40,
                                          }}
                                          axisLeft={{
                                            legendOffset: -45,
                                            legend:
                                              jurimetric.data.config.labels.y,
                                            legendPosition: 'middle',
                                            format: (value) => {
                                              value = Number(value);
                                              if (value >= 1000000) {
                                                value = value / 1000000 + 'M';
                                              } else if (value >= 1000) {
                                                value = value / 1000 + 'K';
                                              }
                                              return value;
                                            },
                                          }}
                                          data={jurimetric.data.data}
                                          keys={jurimetric.data.config.keys}
                                          indexBy={
                                            jurimetric.data.config.indexBy
                                          }
                                          getColors={(value) => {
                                            return jurimetric.data.config
                                              .colors[value.data.label];
                                          }}
                                          labelTextColor={'#fff'}
                                        />
                                      </Col>
                                    );
                                  case 'pie':
                                    return (
                                      <Col
                                        style={{ marginTop: 10 }}
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={12}
                                      >
                                        <h5 style={{ textAlign: 'center' }}>
                                          {jurimetric.title}
                                        </h5>
                                        <p style={{ textAlign: 'center' }}>
                                          {jurimetric.subtitle}
                                        </p>
                                        <Turivius.TuriviusPieChart
                                          style={{ width: '100%', height: 300 }}
                                          margin={{
                                            top: 20,
                                            right: 80,
                                            bottom: 80,
                                            left: 100,
                                          }}
                                          key={`relator-${i}`}
                                          id={`relator-${i}`}
                                          data={jurimetric.data.data}
                                          legends={[]}
                                          arcLabelsTextColor={'#fff'}
                                          valueFormat={(value) =>
                                            `${Number(value).toLocaleString(
                                              'pt-BR',
                                              {
                                                minimumFractionDigits: 2,
                                              },
                                            )}%`
                                          }
                                          getColors={(value) => {
                                            return jurimetric.data.config
                                              .colors[value.data.label];
                                          }}
                                        />
                                      </Col>
                                    );
                                  case 'swarm':
                                    return (
                                      <Col
                                        style={{ marginTop: 10 }}
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={12}
                                      >
                                        <h5 style={{ textAlign: 'center' }}>
                                          {jurimetric.title}
                                        </h5>
                                        <p style={{ textAlign: 'center' }}>
                                          {jurimetric.subtitle}
                                        </p>
                                        <Turivius.TuriviusSwarmChart
                                          style={{ width: '100%', height: 300 }}
                                          //key={`relator-${i}`}
                                          identify={`relator-${i}`}
                                          data={jurimetric.data.data}
                                          groups={jurimetric.data.config.groups.reverse()}
                                          getColors={(value) => {
                                            return jurimetric.data.config
                                              .colors[value.data.group];
                                          }}
                                          axisTop={null}
                                          axisRight={null}
                                          axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 8,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 40,
                                          }}
                                          size={{
                                            key: 'value',
                                            values: [
                                              jurimetric.data.config.values.min,
                                              jurimetric.data.config.values.max,
                                            ],
                                            sizes: [8, 20],
                                          }}
                                          tooltip={(value) => {
                                            return (
                                              <div
                                                style={{
                                                  backgroundColor: '#fff',
                                                  padding: 4,
                                                }}
                                              >
                                                <div
                                                  key={value.id}
                                                  style={{
                                                    color: value.color,
                                                    margin: '4px 0',
                                                    width: 12,
                                                    height: 12,
                                                    display: 'inline-block',
                                                    backgroundColor:
                                                      value.color,
                                                    position: 'absolute',
                                                  }}
                                                ></div>
                                                <span
                                                  style={{ paddingLeft: 15 }}
                                                >
                                                  {
                                                    new Date(
                                                      value.data.timestamp,
                                                    )
                                                      .toLocaleString('pt-BR')
                                                      .split(' ')[0]
                                                  }
                                                  :
                                                </span>
                                                <strong>
                                                  &nbsp;{value.data.value}
                                                </strong>
                                              </div>
                                            );
                                          }}
                                          margin={{
                                            top: 40,
                                            right: 60,
                                            bottom: 50,
                                            left: 80,
                                          }}
                                        />
                                      </Col>
                                    );
                                  default:
                                    return null;
                                }
                                // const isOnFocus = on_focus === `${card.id}${i}`
                                // return jurimetric && jurimetric.plot && jurimetric.plot.data && jurimetric.plot.layout ?
                                //     <Col
                                //         className={isOnFocus || window.detectMob() ? "jurimetric-plot-col" : "jurimetric-plot-col disabled"}
                                //         key={`${card.id}${i}`}
                                //         xs={24}
                                //         sm={24}
                                //         lg={isOnFocus || window.detectMob() ? 24 : 12}
                                //         onClick={() => !isOnFocus ? this.setState({ on_focus: window.detectMob() ? null : `${card.id}${i}` }) : null}>
                                //         <div className={`close-icon ${isOnFocus && !window.detectMob() ? 'visible' : ''}`}
                                //             onClick={() => this.setState({ on_focus: null })}>
                                //             <FontAwesomeIcon icon={faTimes} />
                                //         </div>
                                //         <Plot
                                //             data={jurimetric.plot.data}
                                //             layout={{ ...jurimetric.plot.layout, autosize: true, showlegend: isOnFocus }}
                                //             config={{ staticPlot: false, displayModeBar: true, responsive: true }}
                                //             useResizeHandler={true}
                                //             style={{ width: "100%", height: "100%", marginBottom: 10 }} />
                                //     </Col> : null
                              })}
                            </Row>
                          </Grid>
                        ) : (
                          <div className="modal-jurimetrics-notfound-container">
                            <img
                              src="/notfound.svg"
                              alt="Nenhuma Jurimetria Encontrada"
                              className="modal-jurimetrics-notfound-image"
                            />
                            <div>
                              <span className="title">
                                {' '}
                                Estamos analisando estes dados{' '}
                              </span>
                              <br />
                              <span className="subtitle">
                                Em breve a jurimetria estará disponível
                              </span>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="modal-searching-jurimetrics-container">
                          <img
                            className="modal-searching-jurimetrics-image"
                            src="/search.gif"
                            alt="searching jurimetrics..."
                          />
                          <br />
                          <span className="title">
                            {' '}
                            Buscando jurimetria...{' '}
                          </span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-jurimetrics-footer">
              <Button className="grey darken-1" onClick={this.handleClose}>
                Fechar
              </Button>
              <CardActions
                card={card}
                collectionId={collectionId}
                share
                download
                cite
                remove
              />
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  jurimetrics: state.jurimetrics.jurimetrics,
  validEntities: state.jurimetrics.validEntities,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(JurimetricsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MoreInfoResultCard);
