import React from 'react';
import { Button, MaskedInput, InputGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import './DateFilter.scss';
import { parseISO, format, isValid } from 'date-fns';

class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date_inicial: null,
      date_final: null,
      error_initial: false,
      error_final: false,
      reloadingEnd: false,
      reloadingBegin: false,
    };
  }

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  handleChangeSelectionBegin = (value) => {
    this.setState({ date_inicial: value });
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);
    filtersLocal.date_range[0] = value;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));

    // const { filter, filters, updateSelectedFilters } = this.props;
    // const dateInput = new Date(this.getMMDDYYYYDate(value));

    // if (!this.isValidDate(dateInput)) {
    //   this.setState({ error_initial: true });
    // } else {
    //   this.setState({ error_initial: false, date_inicial: value }, () => {
    //     updateSelectedFilters({
    //       date_inicial: this.state.date_inicial,
    //       date_final: this.state.date_final,
    //     });
    //     this.updateDateRangeInURL();
    //   });
    // }

    // this.props.setTmpFilters(filter.field_name, {
    //   ...filters[filter.field_name],
    //   0: value,
    // });
  };

  handleChangeSelectionEnd = (value) => {
    this.setState({ date_final: value });
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);
    filtersLocal.date_range[1] = value;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));

    // const { filter, filters, updateSelectedFilters } = this.props;
    // const dateInput = new Date(this.getMMDDYYYYDate(value));

    // if (!this.isValidDate(dateInput)) {
    //   this.setState({ error_final: true });
    // } else {
    //   this.setState({ error_final: false, date_final: value }, () => {
    //     updateSelectedFilters({
    //       date_inicial: this.state.date_inicial,
    //       date_final: this.state.date_final,
    //     });
    //     this.updateDateRangeInURL();
    //   });
    // }

    // this.props.setTmpFilters(filter.field_name, {
    //   ...filters[filter.field_name],
    //   1: value,
    // });
  };

  handleSelectionEndBlur = (e) => {
    const { filter, filters } = this.props;
    const dateStringInput = e.target.value;
    const dateInput = new Date(this.getMMDDYYYYDate(dateStringInput));

    if (this.isValidDate(dateInput)) {
      if (
        dateInput <
        new Date(this.getMMDDYYYYDate(filters[filter.field_name]['0']))
      ) {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: dateStringInput,
          1: filters[filter.field_name]['0'],
        });
      } else {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          1: dateStringInput,
        });
      }

      if (this.props.context) {
        posthog.capture(this.props.context, {
          action: 'change_filter',
          id: filter.field_name,
        });
        postHubSpotEvent('app_changefilters', {
          app_changefilters: this.props.context,
        });
      }
    } else {
      this.setState({
        error_initial: true,
        error_final: true,
      });
    }
  };

  updateDateRangeInURL = () => {
    const { date_inicial, date_final } = this.state;
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);

    filtersLocal.date_range = {
      0: date_inicial,
      1: date_final,
    };

    localStorage.setItem('filters', JSON.stringify(filtersLocal));

    if (
      this.isValidDate(new Date(this.getMMDDYYYYDate(date_inicial))) &&
      this.isValidDate(new Date(this.getMMDDYYYYDate(date_final)))
    ) {
      const url = new URL(window.location);

      const filters = url.searchParams.get('filters');
      let filtersObject = {};

      if (filters) {
        filtersObject = JSON.parse(decodeURIComponent(filters));
      }

      filtersObject.date_range = {
        0: date_inicial,
        1: date_final,
      };

      const updatedFilters = encodeURIComponent(JSON.stringify(filtersObject));

      url.searchParams.set('filters', updatedFilters);

      window.history.replaceState({}, '', url);
    }
  };

  handleSelectionBeginBlur = (e) => {
    const { filter, filters } = this.props;
    const dateStringInput = e.target.value;
    const dateInput = new Date(this.getMMDDYYYYDate(dateStringInput));

    if (this.isValidDate(dateInput)) {
      if (
        dateInput >
        new Date(this.getMMDDYYYYDate(filters[filter.field_name]['1']))
      ) {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: filters[filter.field_name]['1'],
          1: dateStringInput,
        });
      } else {
        this.props.setTmpFilters(filter.field_name, {
          ...filters[filter.field_name],
          0: dateStringInput,
        });
      }
      if (this.props.context) {
        posthog.capture(this.props.context, {
          action: 'change_filter',
          id: filter.field_name,
        });
        postHubSpotEvent('app_changefilters', {
          app_changefilters: this.props.context,
        });
      }
    } else {
      this.setState({
        error_initial: true,
      });
      setTimeout(() => {
        this.setState({
          error_initial: false,
        });
      }, 200);
    }
  };

  getMMDDYYYYDate(str) {
    const date = str.split('/');
    return `${date[1]}/${date[0]}/${date[2]}`;
  }

  cleanDates() {
    const { filters, filter } = this.props;
    this.setState({
      date_inicial: null,
      date_final: null,
      error_initial: true,
      error_final: true,
    });
  }

  componentDidMount() {
    const { filters, filter, dateRange } = this.props;

    if (filters && filters[filter.field_name]) {
      this.setState({
        date_inicial: dateRange.start ?? filters[filter.field_name]['0'],
        date_final: filters[filter.field_name]['1'],
      });
    }
  }

  formatDate(dateString) {
    if (dateString) {
      const parsedDate = parseISO(dateString);
      if (isValid(parsedDate) && dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        // Se for válida, formata para o formato dd/MM/yyyy
        return format(parsedDate, 'dd/MM/yyyy');
      }
      return dateString;
    }
    return dateString;
  }

  whatDate(firstDate, secondDate) {
    let filtersLocal = localStorage.getItem('whats_year');
    console.log(filtersLocal, 'filtersLocal');
    if (filtersLocal === 'any_date') {
      return this.formatDate(secondDate);
    }
    return this.formatDate(firstDate) ?? this.formatDate(secondDate);
  }

  render() {
    const { error_initial, error_final, date_inicial, date_final } = this.state;
    const { filters, filter, isDateFilterDisabled, dateRange } = this.props;

    return filters && filters[filter.field_name] ? (
      <div className="date-filter-box" id="filter-date">
        <div className="date-filter-title-container">
          <span className="date-filter-title" />
          <Button size="xs" appearance="link" onClick={() => this.cleanDates()}>
            Limpar
          </Button>
        </div>

        <InputGroup
          className="date-filter-input-group"
          style={{ marginTop: 12 }}
        >
          <MaskedInput
            id="initial-date-filter"
            className={
              error_initial
                ? 'date-filter-input-error'
                : 'date-filter-masked-input'
            }
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            guide={true}
            showMask={true}
            keepCharPositions={false}
            placeholder={'__/__/____'}
            value={this.whatDate(dateRange.start, date_inicial)}
            onChange={this.handleChangeSelectionBegin}
            onBlur={this.handleSelectionBeginBlur}
            disabled={isDateFilterDisabled}
          />
          <InputGroup.Addon className="date-filter-addon">até</InputGroup.Addon>
          <MaskedInput
            id="final-date-filter"
            className={
              error_final
                ? 'date-filter-input-error'
                : 'date-filter-masked-input'
            }
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            guide={true}
            showMask={true}
            keepCharPositions={false}
            placeholder={'__/__/____'}
            value={this.whatDate(dateRange.end, date_final)}
            onChange={this.handleChangeSelectionEnd}
            onBlur={this.handleSelectionEndBlur}
            disabled={isDateFilterDisabled}
          />
        </InputGroup>
      </div>
    ) : null;
  }
}

export default DateFilter;
